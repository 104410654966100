import { ToggleFeedSubscription, AddItems, MarkAsRead, ResetNews, UpdateFeedList, MarkAllAsRead } from './news.actions';
import { createReducer, Action, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { INewsItemRead, INewsFeedItem, INewsFeedSourceName } from '../../pages/home/news/news.service';

export const newsFeatureKey = 'news';

export interface Feeds {
  [key: string]: {
    enabled: boolean;
    description: string;
    name: string;
  };
}

export interface State {
  [key: string]: {
    enabled: boolean;
    description: string;
    items: INewsItemRead[];
  };
}

export const initialState: State = {};

const newsReducer = createReducer<State, Action>(
  initialState,
  on(ResetNews, () => initialState),
  on(UpdateFeedList, (state: State, payload: { feeds: { [key: string]: INewsFeedSourceName } }) => {
    let newState: State = {};
    for (const feedId of Object.keys(payload.feeds)) {
      // Add unknown feedId to the list and enable it
      newState = {
          ...newState,
          [feedId]: {
            enabled: state.hasOwnProperty(feedId) ? state[feedId].enabled : true,
            description: payload.feeds[feedId].name,
            items: state.hasOwnProperty(feedId) ? state[feedId].items : []
          }
      };
    }
    return newState;
  }),
  on(ToggleFeedSubscription, (state: State, payload: { feedId: string }): State => ({
      ...state,
      [payload.feedId]: {
        ...state[payload.feedId],
        enabled: !state[payload.feedId].enabled,
        items: !state[payload.feedId].enabled ? state[payload.feedId].items : []
      }
    })),
  on(AddItems, (state: State, payload: { items: { [key: string]: INewsFeedItem[] } }) => {
    let newItemMap: State = {};

    for (const feedId of Object.keys(state)) {
      newItemMap = {
        ...newItemMap,
        [feedId]: {
          ...state[feedId],
          items: [...state[feedId].items]
        }
      };
    }

    for (const feedId of Object.keys(payload.items)) {
      if (!state.hasOwnProperty(feedId)) {
        continue;
      }

      for (const newsItem of payload.items[feedId]) {
        if (!newsItem.title) {
          continue;
        }

        // check if news item is alread in the news state
        const itemIndex = state[feedId].items.findIndex((item: INewsItemRead) => item.id === newsItem.id);

        if (itemIndex !== -1) {
          const itemContentChanged = newItemMap[feedId].items[itemIndex].title !== newsItem.title
            || newItemMap[feedId].items[itemIndex].description !== newsItem.description
            || newItemMap[feedId].items[itemIndex].date !== newsItem.date
            || newItemMap[feedId].items[itemIndex].link !== newsItem.link
            || JSON.stringify(newItemMap[feedId].items[itemIndex].content) !== JSON.stringify(newsItem.content)
            || JSON.stringify(newItemMap[feedId].items[itemIndex].images) !== JSON.stringify(newsItem.images);
          const readState = itemContentChanged ? true : newItemMap[feedId].items[itemIndex].read;

          // Update exisiting news item
          newItemMap[feedId].items[itemIndex] = {
            ...newItemMap[feedId].items[itemIndex],
            ...newsItem,
            read: readState
          };
        } else {
          const newsItemRead: INewsItemRead = {
            ...newsItem,
            read: false,
            feedId
          };

          newItemMap[feedId].items.push(newsItemRead);
        }
      }
    }

    return newItemMap;
  }),
  on(MarkAsRead, (state: State, payload: { newsItemId: string }) => markItemAsRead(state, payload.newsItemId)),
  on(MarkAllAsRead, (state: State) => markItemAsRead(state))
);

export const reducer = (state: State | undefined, action: Action) => newsReducer(state, action);

export const selectNews = createFeatureSelector<State>(newsFeatureKey);

export const selectFeedSubscriptions = createSelector(selectNews, (state: State) => {
  let subscriptions = {};
  for (const feedId of Object.keys(state)) {
    subscriptions = {
      ...subscriptions,
      [feedId]: {
        enabled: state[feedId].enabled,
        name: state[feedId].description
      }
    };
  }
  return subscriptions;
});

export const selectActiveFeedSubscriptions = createSelector(selectFeedSubscriptions, (feeds: Feeds) => Object.keys(feeds)
  .filter((feedId: string) => feeds[feedId].enabled));


export const selectNewsItems = (offset: number, limit: number, filter: string, hideRead: boolean) => createSelector(
  selectNews,
  (state: State) => {
    // Sort map by key (feedId)
    const orderedFeedItemsMap = sortByKey(state);

    // Make List of Items
    const items: INewsItemRead[] = [];
    for (const feedId of Object.keys(orderedFeedItemsMap)) {
      if (orderedFeedItemsMap[feedId].enabled) {
        items.push(...state[feedId].items);
      }
    }

    // Order the items
    let orderedByDateItems = sortByDate(items);

    // filter by read status
    if(hideRead) {
      orderedByDateItems = orderedByDateItems.filter((news: INewsItemRead): boolean => !news.read);
    }

    // filter news by string
    if(filter && filter.trim()) {
      orderedByDateItems = orderedByDateItems.filter((news: INewsItemRead): boolean =>
        news.title.toLowerCase().includes(filter.toLowerCase())
          || news.description.toLowerCase().includes(filter.toLowerCase())
          || news.content.some((content: string): boolean => content.toLowerCase().includes(filter.toLowerCase())));
    }

    const maxLimit = (offset ?? 0) + (limit ?? 5) > orderedByDateItems.length ? orderedByDateItems.length : (offset ?? 0) + (limit ?? 5);
    return orderedByDateItems.slice(offset, maxLimit);
  }
);

export const selectNewsCount = createSelector(selectNews, (state: State) => {
  let count = 0;

  for (const feedId of Object.keys(state)) {
    count = count + state[feedId].items.length;
  }
  return count;
});

export const selectUnreadCount = createSelector(selectNews, (state: State) => {
  let count = 0;

  for (const feedId of Object.keys(state)) {
    for (const newsItem of state[feedId].items) {
      if (!newsItem.read) {
        count++;
      }
    }
  }
  return count;
});

export const selectRead = (id: string) => createSelector(
  selectNews,
  (state: State) => {
    for (const feedId of Object.keys(state)) {
      for (const newsItem of state[feedId].items) {
        if (newsItem.id === id) {
          return newsItem.read;
        }
      }
    }
    return false;
  }
);

// Helper functions

const markItemAsRead = (state: State, itemId?: string) => {
  let newState: State = {};

  for (const feedId of Object.keys(state)) {
    newState = {
      ...newState,
      [feedId]: {
        ...state[feedId], items: []
      }
    };

    for (const newsItem of state[feedId].items) {
      newState[feedId].items.push({
        ...newsItem,
        read: (!itemId || newsItem.id === itemId) ? true : newsItem.read
      });
    }
  }

  return newState;
};

const sortByDate = (items: INewsItemRead[]) => items.sort((a: INewsItemRead, b: INewsItemRead) => {
  const aDate = new Date(a.date);
  const bDate = new Date(b.date);

  return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
});

const sortByKey = (state: State) => {
  const ordered: State = {};
  Object.keys(state)
    .sort()
    .forEach((feedId: string) => {
      ordered[feedId] = {
        ...state[feedId],
        items: [...state[feedId].items]
      };
    });
  return ordered;
};
