import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromSettings from './settings.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SettingsEffects } from './settings.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSettings.settingsFeatureKey, fromSettings.reducer),
    EffectsModule.forFeature([SettingsEffects])
  ]
})
export class SettingsModule { }
