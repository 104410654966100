import { createAction, props } from '@ngrx/store';
import { PushNotificationSchema } from '@capacitor/push-notifications';

export const AddPushNotification = createAction(
  '[Push Notification] Add Push Notification',
  props<{ pushNotification: PushNotificationSchema }>()
);
export const RemovePushNotification = createAction('[Push Notification] Remove Push Notification', props<{ id: string }>());
export const ClearPushNotifications = createAction('[Push Notification] Clear All Push Notifications');
export const ResetPushNotification = createAction('[Push Notification] Reset');
