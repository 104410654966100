<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ 'canteen.information.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        {{ 'core.done' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-list-header>
      {{ 'canteen.information.types' | translate }}
    </ion-list-header>
    <ion-item *ngFor="let type of types" class="ion-text-wrap">
        <img width="50px" height="20px" [src]="canteenService.getPictogram(type.id)" role="img" [alt]="type.id" />
      <ion-label>
        {{ canteenService.getLocalizedString(type.name) }}
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list *ngIf="additives">
    <ion-list-header>
      {{ 'canteen.information.additives' | translate }}
    </ion-list-header>
    <ion-item *ngFor="let additive of additives" class="ion-text-wrap">
      <ion-label>
        <h2>{{ additive.id }}</h2>
        {{ canteenService.getLocalizedString(additive.name) }}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
