<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="modalController.dismiss()"> {{ 'core.done' | translate }} </ion-button>
    </ion-buttons>

    <ion-title>{{ 'settings.information.changelog' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card *ngFor="let version of versions">
    <ion-card-header>
      <ion-card-subtitle>{{ version.date }}</ion-card-subtitle>
      <ion-card-title>Version {{ version.version }}</ion-card-title>
    </ion-card-header>

    <ion-card-content *ngIf="version.description" [innerHTML]="version.description"></ion-card-content>

    <ion-card-content *ngIf="version.changes">
      <ul class="list">
        <li *ngFor="let change of version.changes">{{ change }}</li>
      </ul>
    </ion-card-content>
  </ion-card>
</ion-content>
