import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCampusId from './campus-id.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CampusIdEffects } from './campus-id.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCampusId.campusIdFeatureKey, fromCampusId.reducer),
    EffectsModule.forFeature([CampusIdEffects])
  ]
})
export class CampusIdModule {}
