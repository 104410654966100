import { Component, OnInit, Input } from '@angular/core';
import { IStation, DepartureMonitorService } from '../departure-monitor.service';
import { ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { ChangeRememberedStation } from 'src/app/ngrx/settings/settings.actions';
import { Observable } from 'rxjs';

import * as fromSettings from 'src/app/ngrx/settings/settings.reducer';

@Component({
  selector: 'app-stations',
  templateUrl: './stations.page.html',
  styleUrls: ['./stations.page.scss']
})
export class StationsPage implements OnInit {
  public stations: IStation[];
  private rememberStation: boolean;
  private station: Observable<{ remember: boolean; remembered: IStation }>;

  constructor(
    private departureService: DepartureMonitorService,
    private modalController: ModalController,
    private store: Store<AppState>
  ) {
    this.station = this.store.pipe(select(fromSettings.getDeparture));
  }

  ngOnInit() {
    this.departureService.stations().subscribe(stations => {
      this.stations = stations;
    });
    this.station.subscribe((station: { remember: boolean; remembered: IStation }) => {
      this.rememberStation = station.remember;
    });
  }

  public dismiss(station?: IStation) {
    if (station && this.rememberStation) {
      this.store.dispatch(ChangeRememberedStation({ station }));
    }
    this.modalController.dismiss(station);
  }
}
