import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonToggle } from '@ionic/angular';
import { AppState } from 'src/app/store';
import { Store, select } from '@ngrx/store';
import { TogglePush, TogglePushChannel, ChangeLanguage } from 'src/app/ngrx/settings/settings.actions';
import { IPushChannel, getPushEnabled, getPushChannelEnabled } from 'src/app/ngrx/settings/settings.reducer';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { Swiper } from 'swiper';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.page.html',
  styleUrls: ['./wizard.page.scss']
})
export class WizardPage implements OnInit {
  @ViewChild('swiper', { static: false }) slides: ElementRef | undefined;
  public languages: { lang: string; translationKey: string }[] = [];
  public push$: Observable<boolean>;
  public pushChannelGeneral$: Observable<boolean>;
  public pushChannelSports$: Observable<boolean>;
  public pushChannelLibrary$: Observable<boolean>;
  public isEnd = new BehaviorSubject(false);

  constructor(public modalController: ModalController, private store: Store<AppState>, public translate: TranslateService) {
    this.push$ = this.store.pipe(select(getPushEnabled));
    this.pushChannelGeneral$ = this.store.select(getPushChannelEnabled(IPushChannel.GENERAL));
    this.pushChannelSports$ = this.store.select(getPushChannelEnabled(IPushChannel.SPORTS));
    this.pushChannelLibrary$ = this.store.select(getPushChannelEnabled(IPushChannel.LIBRARY));
  }

  ngOnInit() {
    this.languages = this.translate.getLangs().map((value: string) => ({
      lang: value,
      translationKey: `settings.lang.${value}`
    }));
  }

  public slideChanged() {
    this.isEnd.next(this.slides.nativeElement.swiper.isEnd);
  }

  public next() {
    this.slides.nativeElement.swiper.slideNext();
  }

  public togglePush(event: IonToggle): void {
    this.store.dispatch(TogglePush({ enabled: event.checked }));
  }

  public togglePushChannel(event: IonToggle): void {
    const channel: IPushChannel = event.name as IPushChannel;
    this.store.dispatch(TogglePushChannel({ channel, enabled: event.checked }));
  }

  public selectLanguage(event: any) {
    const language = event.detail.value;
    this.store.dispatch(ChangeLanguage({ language }));
  }

  public done() {
    Preferences.set({ key: 'wizardCompleted', value: 'true' });
    this.modalController.dismiss();
  }
}
