import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CampusIdFailed, CampusIdInit, CampusIdNoop, CampusIdStore, CampusIdValidate } from './campus-id.actions';
import { CampusIdService, TOTPValidation } from './campus-id.service';

@Injectable()
export class CampusIdEffects {
  campusIdInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampusIdInit),
      switchMap(() => this.campusIdService.requestTOTP().pipe(
          map((totpSecret: { uuid: string; secret: string }) => CampusIdStore({ ...totpSecret })),
          catchError((error: HttpErrorResponse) => of(CampusIdFailed({ error })))
        ))
    )
  );

  campusIdValidate = createEffect(() =>
    this.actions$.pipe(
      ofType(CampusIdValidate),
      switchMap((props: { uuid: string; totp: string }) => this.campusIdService.validate(props.uuid, props.totp).pipe(
          map((totpValidation: TOTPValidation) => {
            if (!totpValidation.valid) {
              return CampusIdInit();
            }
            return CampusIdNoop();
          })
        ))
    )
  );

  constructor(private actions$: Actions, private campusIdService: CampusIdService) {}
}
