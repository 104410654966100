import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()
export class PushNotificationEffects {
  // @Effect()
  // loadNewss$ = this.actions$.pipe(
  //   ofType(),
  //   /** An EMPTY observable only emits completion. Replace with your own observable API request */
  //   concatMap(() => EMPTY)
  // );

  constructor(private actions$: Actions) {}
}
