import { createAction, props } from '@ngrx/store';
import { INewsFeedItem, INewsFeedSourceName } from '../../pages/home/news/news.service';

export const ToggleFeedSubscription = createAction('[News] Toggle Feed Subscriptions', props<{ feedId: string }>());
export const FetchFeedList = createAction('[News] Fetch Feed List');
export const UpdateFeedList = createAction('[News] Update Feed List', props<{ feeds: { [key: string]: INewsFeedSourceName } }>());
export const FetchItems = createAction('[News] Fetch News Items of active Feeds', props<{ feedIds: string[] }>());
export const AddItems = createAction('[News] Add News Items', props<{ items: { [key: string]: INewsFeedItem[] } }>());
export const MarkAsRead = createAction('[News] Mark as read', props<{ newsItemId: string }>());
export const MarkAllAsRead = createAction('[News] Mark all news items as read');
export const Noop = createAction('[News] Noop');

export const ResetNews = createAction('[News] Reset');
