import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetResult, Preferences } from '@capacitor/preferences';
import { differenceInSeconds } from 'date-fns';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppInfo } from '@capacitor/app';
import { DeviceInfo } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store';
import { getPushEnabled } from 'src/app/ngrx/settings/settings.reducer';
import { PushNotifications } from '@capacitor/push-notifications';
import { PwaPushNotificationPlugin } from 'src/app/pwaPushNotification';
import { Capacitor } from '@capacitor/core';
import { PWAService } from '../pwa.service';

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface OAuthCredentials<T> {
  expires_in: T;
  token_type: string;
  refresh_token: string;
  access_token: string;
  scope: string;
}

export interface OAuthTokenInfo<T> {
  uid: string;
  name: string;
  sn: string;
  givenname: string;
  scope: Array<string>;
  roles: Array<string>;
  realm: string;
  token_type: string;
  expires_in: T;
  access_token: string;
  employeeNumber?: string;
  'x-de-tudortmund-matr'?: string;
}

export enum SignOutType {
  REFRESH_TOKEN_INVALID = 'refresh_token_invalid',
  USER_INITIATED = 'user_initiated',
  APP_RESET = 'app_reset',
  UNKNOWN = 'unknown',
}

const API_URL = environment.apiUrl;
const API_SERVICE = `${API_URL}/oauth2/v2`;
export const API_ACCESS_TOKEN = `${API_SERVICE}/access_token`;
export const API_TOKEN_INFO = `${API_SERVICE}/tokeninfo`;
export const API_LOGOUT = `${API_SERVICE}/logout`;

export const STORAGE_KEY = 'oauth';
export const REFRESH_THRESHOLD = 300; // 5 minutes; value in seconds

interface StorageOAuth {
  oauth_credentials: OAuthCredentials<Date> | null;
  token_info: OAuthTokenInfo<Date> | null;
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public oauth: StorageOAuth = { oauth_credentials: null, token_info: null };
  private refresh: Observable<OAuthCredentials<Date>> | null = null;
  private pushEnabled$: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private alertController: AlertController,
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {
    Preferences.get({ key: STORAGE_KEY }).then((value: GetResult) => {
      if(value.value) {
        const oauth = JSON.parse(value.value) as StorageOAuth;
        this.oauth = {
          oauth_credentials: oauth.oauth_credentials,
          token_info: oauth.token_info
        };
      }
    });
    this.pushEnabled$ = this.store.pipe(select(getPushEnabled));
  }

  public isAuthenticated(): boolean {
    return !!this.oauth?.oauth_credentials;
  }

  public expireToken(): void {
    this.oauth = {
      ...this.oauth,
      oauth_credentials: {
        ...this.oauth.oauth_credentials,
        expires_in: new Date()
      }
    };

    Preferences.set({
      key: STORAGE_KEY,
      value: JSON.stringify(this.oauth)
    });
  }

  public isExpired(): boolean {
    return this.oauth.oauth_credentials === null ||
      (this.isAuthenticated() && (differenceInSeconds(new Date(this.oauth.oauth_credentials.expires_in), new Date()) < REFRESH_THRESHOLD));
  }

  /**
   * Authenticate with LoginCredentials
   *
   * @param credentials login credentials consisting of username and password
   * @returns Observable<OAuthCredentials>
   */
   public authenticate(credentials: LoginCredentials): Observable<OAuthCredentials<Date> | HttpErrorResponse> {
    const oauthBody = { username: credentials.username, password: credentials.password, grant_type: 'password' };
    return this.http
      .post<OAuthCredentials<number>>(API_ACCESS_TOKEN, JSON.stringify(oauthBody), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(
        map((oauthCredentials: OAuthCredentials<number>) => ({
          ...oauthCredentials,
          expires_in: new Date(new Date().getTime() + oauthCredentials.expires_in * 1000)
        })),
        switchMap((oauthCredentials: OAuthCredentials<Date>) => {
          this.oauth = {
            ...this.oauth,
            oauth_credentials: oauthCredentials
          };
          return of(Preferences.set({
            key: STORAGE_KEY,
            value: JSON.stringify(this.oauth)
          })).pipe(
            tap(() => {
              // Change the Push Service Implementation for Native (IOS, Android) or Web (PWA)
              const pushImplementation = Capacitor.isNativePlatform() ? PushNotifications : PwaPushNotificationPlugin.getInstance();
              // Registering to push service with an authenticated request. This ensures that the user account gets saved in the
              // push notification database
              return this.pushEnabled$.pipe(take(1)).subscribe(async (enabled: boolean) => {
                if(enabled) {
                  const permissionStatus = await pushImplementation.requestPermissions();
                  console.info(`[PushNotification] permission was ${permissionStatus.receive}`);
                  if (permissionStatus.receive === 'granted') {
                    console.info(`[PushNotification] registering...`);
                    await pushImplementation.register();
                  }
                 }
              });
            }),
            map(() => oauthCredentials)
          );
        })
      );
  }

  public handleAuthenticatedRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('[Authentication Interceptor]', 'authentication state', this.isAuthenticated());
    console.log('[Authentication Interceptor]', 'token expiration state', this.isExpired());
    if(this.isExpired() && this.isAuthenticated()) {
      if(this.refresh === null) {
        this.refresh = this.refreshToken().pipe(
          tap(() => {
            console.log('[Authentication Interceptor]', 'attempting to refresh access token');
          }),
          catchError((error: HttpErrorResponse) => {
            if(error.status === HttpStatusCode.BadRequest) {
              this.oauth = { oauth_credentials: null, token_info: null };
            }
            return defer(() => of(Preferences.set({ key: STORAGE_KEY, value: JSON.stringify(this.oauth) }))).pipe(
              switchMap(() => {
                return this.handleRefreshError(error)
              }),
              switchMap(() => {
                throw error;
              })
            );
          }),
          switchMap((refreshedOAuthCredentials: OAuthCredentials<Date>) => {
            this.oauth = {
              ...this.oauth,
              oauth_credentials: refreshedOAuthCredentials
            };
            return of(Preferences.set({
              key: STORAGE_KEY,
              value: JSON.stringify(this.oauth)
            })).pipe(
              tap(async () => {
                const stored = await Preferences.get({ key: STORAGE_KEY });
                if(stored.value !== JSON.stringify(this.oauth)) {
                  // Log if stored oauth credentials are different from the actual refreshed credentials
                  Promise.all([Capacitor.isNativePlatform()? App.getInfo(): PWAService.getInfo(), Device.getInfo()]).then((values: [AppInfo, DeviceInfo]) => {
                    const body = new URLSearchParams();
                    body.set('type', 'CREDENTIALS_MISMATCH');
                    body.set('version', values[0].version);
                    body.set('build', values[0].build);
                    body.set('os', values[1].operatingSystem);
                    body.set('os_version', values[1].osVersion);
                    body.set('webViewVersion', values[1].webViewVersion);
                    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
                    this.http.post(`${environment.apiUrl}/logout-statistics`, body, { headers }).subscribe();
                  });
                }
              }),
              map(() => refreshedOAuthCredentials)
            );
          }),
          shareReplay(1)
        );
      }

      return this.refresh.pipe(
        switchMap(() => next.handle(this.addAuthorizationHeader(request, this.oauth.oauth_credentials.access_token))),
        tap(() => {
          this.refresh = null;
        }),
        catchError((error: HttpErrorResponse) => {
          this.refresh = null;
          console.error('Error occured while refreshing the access token', error);
          throw error;
        })
      );
    }

    if(this.isAuthenticated()) {
      return next.handle(this.addAuthorizationHeader(request, this.oauth.oauth_credentials.access_token));
    }
    return next.handle(request);
  }

  public refreshToken(): Observable<OAuthCredentials<Date> | HttpErrorResponse> {
    const body = {
      refresh_token: this.oauth.oauth_credentials?.refresh_token,
      grant_type: 'refresh_token',
    };

    return this.http
      .post<OAuthCredentials<number>>(API_ACCESS_TOKEN, JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        map((oauthCredentials: OAuthCredentials<number>) => ({
          ...oauthCredentials,
          expires_in: new Date(new Date().getTime() + oauthCredentials.expires_in * 1000)
        })),
        switchMap((oauthCredentials: OAuthCredentials<Date>) => {
          this.oauth = {
            ...this.oauth,
            oauth_credentials: oauthCredentials
          };
          return of(Preferences.set({
            key: STORAGE_KEY,
            value: JSON.stringify(this.oauth)
          })).pipe(
            map(() => oauthCredentials)
          );
        })
      );
  }

  public tokenInfo(oauthCredentials: OAuthCredentials<any>): Observable<OAuthTokenInfo<Date> | HttpErrorResponse> {
    return this.http.get<OAuthTokenInfo<number>>(API_TOKEN_INFO, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oauthCredentials.access_token}`
      }),
    }).pipe(
      map((oauthTokenInfo: OAuthTokenInfo<number>) => ({
        ...oauthTokenInfo,
        expires_in: new Date(new Date().getTime() + oauthTokenInfo.expires_in * 1000)
      })),
      switchMap((tokenInfo: OAuthTokenInfo<Date>) => {
        this.oauth = {
          ...this.oauth,
          token_info: tokenInfo
        };
        return of(Preferences.set({
          key: STORAGE_KEY,
          value: JSON.stringify(this.oauth)
        })).pipe(
          map(() => tokenInfo)
        );
      })
    );
  }

  public signOut(): Observable<void> {
    const body = {
      token: this.oauth.oauth_credentials?.refresh_token,
    };

    return this.http.post<void>(API_LOGOUT, JSON.stringify(body), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        switchMap(() => {
          this.oauth = {
            oauth_credentials: null,
            token_info: null
          };
          return of(Preferences.set({
            key: STORAGE_KEY,
            value: JSON.stringify(this.oauth)
          })).pipe(
            tap(() => {
              // Change the Push Service Implementation for Native (IOS, Android) or Web (PWA)
              const pushImplementation = Capacitor.isNativePlatform() ? PushNotifications : PwaPushNotificationPlugin.getInstance();
              // Registering to push service again. This ensures that the user account gets removed from the
              // push notification database
              return this.pushEnabled$.pipe(take(1)).subscribe(async (enabled: boolean) => {
                if(enabled) {
                  const permissionStatus = await pushImplementation.requestPermissions();
                  console.info(`[PushNotification] permission was ${permissionStatus.receive}`);
                  if (permissionStatus.receive === 'granted') {
                    console.info(`[PushNotification] registering...`);
                    await pushImplementation.register();
                  }
                 }
              });
            }),
            switchMap(() => of(null))
          );
        })
      );
  }

  private addAuthorizationHeader(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  /**
   * Bad Requests (HTTP status code 400) errors that could occur:
   *
   * - "grant (refresh_token) is invalid e.g. refresh_token is expired"
   * - "invalid_scope" error e.g. the scope was extended by the server
   */
   private handleRefreshError(error: HttpErrorResponse | Error): Observable<any> {
    if (error instanceof HttpErrorResponse) {
      if (error.status === HttpStatusCode.BadRequest) {
        // Collect statistics about the logout behavior
        Promise.all([Capacitor.isNativePlatform()? App.getInfo(): PWAService.getInfo(), Device.getInfo()]).then((values: [AppInfo, DeviceInfo]) => {
          const body = new URLSearchParams();
          body.set('type', SignOutType.REFRESH_TOKEN_INVALID);
          body.set('version', values[0].version);
          body.set('build', values[0].build);
          body.set('os', values[1].operatingSystem);
          body.set('os_version', values[1].osVersion);
          body.set('webViewVersion', values[1].webViewVersion);
          const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
          this.http.post(`${environment.apiUrl}/logout-statistics`, body, { headers }).subscribe();
        });

        this.alertController.create({
            header: this.translate.instant('auth.signOut.refreshTokenInvalid.title'),
            message: this.translate.instant('auth.signOut.refreshTokenInvalid.message'),
            buttons: [this.translate.instant('core.alert.ok')],
        }).then((alert: HTMLIonAlertElement) => {
          alert.present();
        });
      }
    }
    return throwError(() => error);
  }
}
