import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CanteenService, IType, IAdditive } from '../canteen.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dish-information',
  templateUrl: './dish-information.page.html',
  styleUrls: ['./dish-information.page.scss']
})
export class DishInformationPage implements OnInit {
  public additives: IAdditive[];
  public types: IType[];

  constructor(private modalController: ModalController, public canteenService: CanteenService, private translate: TranslateService) { }

  ngOnInit() {
    this.canteenService.additives().subscribe(additiveList => {
      this.additives = additiveList;
      this.additives.sort((item1, item2) => {
        const number1 = +item1.id.match(/[0-9]+/gi)[0];
        const number2 = +item2.id.match(/[0-9]+/gi)[0];
        if (number1 < number2) {
          return -1;
        } else if (number2 < number1) {
          return 1;
        } else {
          const letter1 = item1.id.match(/[a-z]/gi);
          const letter2 = item2.id.match(/[a-z]/gi);
          if (letter1.length === 0 || letter2.length === 0) {
            return 0;
          } else {
            const ascii1 = letter1[0].charCodeAt(0);
            const ascii2 = letter2[0].charCodeAt(0);
            if (ascii1 < ascii2) {
              return -1;
            } else if (ascii2 < ascii1) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });
    });
    this.canteenService.types().subscribe((types: IType[]) => {
      this.types = types;
    });
  }

  public dismiss() {
    this.modalController.dismiss();
  }
}
