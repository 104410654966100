import { createReducer, Action, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { AddPushNotification, RemovePushNotification, ClearPushNotifications, ResetPushNotification } from './push-notification.actions';

export const pushNotificationFeatureKey = 'push-notification';

export interface State {
  items: PushNotificationSchema[];
}

export const initialState: State = {
  items: []
};

const pushNotificationReducer = createReducer<State, Action>(
  initialState,
  on(ResetPushNotification, () => initialState),
  on(AddPushNotification, (state: State, action: { pushNotification: PushNotificationSchema }) => {
    if(state.items.findIndex((notification) => (notification.id === action.pushNotification.id)) !== -1) {
      return state;
    }
    return {
      ...state,
      items: [action.pushNotification, ...state.items]
    };
  }),
  on(RemovePushNotification, (state: State, payload: { id: string }) => {
    const filteredPushNotifications = state.items.filter((item: PushNotificationSchema) => {
      if (item.id === payload.id) {
        return false;
      }
      return true;
    });
    return {
      ...state,
      items: filteredPushNotifications
    };
  }),
  on(ClearPushNotifications, (state: State) => ({ ...state, items: [] }))
);

export const reducer = (state: State | undefined, action: Action) => pushNotificationReducer(state, action);

export const selectPushNotification = createFeatureSelector<State>(pushNotificationFeatureKey);
export const getPushNotificationItems = createSelector(selectPushNotification, (state: State) => state.items);
