import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IStation {
  id: string;
  name: string;
}

export interface IStationResult {
  [id: string]: IStationName;
}

export interface IStationName {
  name: string;
}

export interface IStationDeparture {
  date_time: string;
  name: string;
  line: string;
  delay: number;
  direction: string;
  info_title: string;
  info_text: string;
  line_description: string;
  latitude: string;
  longitude: string;
  platform_type: string;
  platform: string;
  transport_type: number;
}

const API_URL = environment.apiUrl;
const API_SERVICE_URL = `${API_URL}/departure/v1`;
const API_STATIONS = `${API_SERVICE_URL}/stations`;
const API_DEPARTURES = `${API_SERVICE_URL}/stations/{0}/departures`;

@Injectable({
  providedIn: 'root'
})
export class DepartureMonitorService {

  constructor(private http: HttpClient) { }

  public stations(): Observable<IStation[]> {
    return this.http.get<IStationResult>(API_STATIONS).pipe(
      map(stationResult => {
        const stations: IStation[] = [];
        for (const key of Object.keys(stationResult)) {
          if (stationResult.hasOwnProperty(key)) {
            stations.push({ id: key, name: stationResult[key].name });
          }
        }
        return stations;
      })
    ).pipe(
      map(stations => this.sortByStationName(stations))
    );
  }

  public departures(stationId: string): Observable<IStationDeparture[]> {
    return this.http.get<IStationDeparture[]>(API_DEPARTURES.format(stationId));
  }

  private sortByStationName(stations: IStation[]): IStation[] {
    return stations.sort((a: IStation, b: IStation) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
