import { createAction, props } from '@ngrx/store';
import { IPushChannel, ILanguage, PersonalContactDetails, IWeatherStyle, IWeatherWindUnit } from './settings.reducer';
import { ICanteen } from '../../pages/home/canteen/canteen.service';
import { IStation } from '../../pages/home/departure-monitor/departure-monitor.service';

export const ToggleCanteenRemember = createAction('[Settings] Toggle Canteen Remember', props<{ enabled: boolean }>());
export const ChangeRememberedCanteen = createAction('[Settings] Change Remembered Canteen', props<{ canteen: ICanteen }>());
export const ToggleDepartureRemember = createAction('[Settings] Toggle Departure Remember', props<{ enabled: boolean }>());
export const ChangeRememberedStation = createAction('[Settings] Change Remembered Station', props<{ station: IStation }>());
export const TogglePush = createAction('[Settings] Toggle Push', props<{ enabled: boolean }>());
export const TogglePushChannel = createAction('[Settings] Toggle Push Channel', props<{ channel: IPushChannel; enabled: boolean }>());
export const ChangeLanguage = createAction('[Settings] Change Settings', props<{ language: ILanguage }>());
export const ChangeInvertTabSwipe = createAction('[Settings] Invert Tab Swipe', props<{ invertTabSwipe: boolean }>());
export const ToggleWeather = createAction('[Settings] Toggle Weather', props<{ enabled: boolean }>());
export const ChangeWeatherStyle = createAction('[Settings] Change Weather Style', props<{ style: IWeatherStyle }>());
export const ChangeWeatherWindUnit = createAction('[Settings] Change Weather Wind Unit', props<{ windUnit: IWeatherWindUnit }>());
export const UpdatePersonalContactDetails = createAction(
  '[Settings] Update personal contact details',
  props<{ personalContactDetails: PersonalContactDetails }>()
);
export const ResetSettings = createAction('[Settings] Reset');
