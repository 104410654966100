import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { DepartureMonitorService, IStation, IStationDeparture } from './departure-monitor.service';
import { ModalController } from '@ionic/angular';
import * as fromSettings from '../../../ngrx/settings/settings.reducer';
import { switchMap } from 'rxjs/operators';
import { StationsPage } from './stations/stations.page';
import { OverlayEventDetail } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class DefaultStationGuard  {
  private station: Observable<{ remember: boolean; remembered: IStation }>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private departureService: DepartureMonitorService,
    private modalController: ModalController
  ) {
    this.station = this.store.pipe(select(fromSettings.getDeparture));
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.station.pipe(
      switchMap((station: { remember: boolean; remembered: IStation }) => {
        if (station.remember && station.remembered) {
          return of(
            this.router.createUrlTree(['/home/departure-monitor', station.remembered.id], {
              queryParams: {
                name: station.remembered.name
              }
            })
          );
        } else {
          return from(
            this.modalController.create({
              component: StationsPage
            })
          ).pipe(
            switchMap((modal: HTMLIonModalElement) => {
              modal.present();
              return from(modal.onDidDismiss()).pipe(
                switchMap((selectedData: OverlayEventDetail<IStation>) => {
                  const selectedStation = selectedData.data;
                  if(selectedStation) {
                    return of(
                      this.router.createUrlTree(['/home/departure-monitor', selectedStation.id], {
                        queryParams: {
                          name: selectedStation.name
                        }
                      })
                    );
                  } else {
                    return of(false);
                  }
                })
              );
            })
          );
        }
      })
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IStationDeparture[]> {
    const stationId = route.paramMap.get('stationId');
    if (stationId) {
      return this.departureService.departures(stationId);
    }
  }
}
