import { HttpContext, HttpContextToken } from '@angular/common/http';

export const isUndefined = (target: any) => target === undefined;
export const isNull = (target: any) => target === null;
export const isArray = (target: any) => Array.isArray(target);
export const isString = (target: any) => typeof target === 'string';
export const isBoolean = (target: any) => typeof target === 'boolean';
export const isNumber = (target: any) => typeof target === 'number';
export const isObjectLike = (target: any) => typeof target === 'object' && target !== null;
export const isObject = (target: any) => isObjectLike(target) && !isArray(target);

export const isPlainObject = (target: any) => {
  if (!isObject(target)) {
    return false;
  }

  const targetPrototype = Object.getPrototypeOf(target);
  return targetPrototype === Object.prototype || targetPrototype === null;
};

export const isFunction = (target: any) =>  typeof target === 'function';

export const hasOwnProperty = (target: object, propertyName: string) => Object.prototype.hasOwnProperty.call(target, propertyName);

// Source: https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
export const parseQueryParams = (query: string)  => {
  if (!query) {
    return null;
  }

  const trimedQuery = query.substr(1);
  const result = {};
  trimedQuery.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const isNullOrUndefinedOrEmpty = (target: string) => target === undefined ||
  target === null || (typeof target === 'string' && target.length === 0);

export const HTTP_CONTEXT = (contextList: HttpContextToken<any>[]) => {
  const httpContext = new HttpContext();
  for (const context of contextList) {
    httpContext.set(context, true);
  }

  return httpContext;
};
