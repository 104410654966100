import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap, map } from 'rxjs/operators';
import { AddItems, UpdateFeedList, FetchFeedList, FetchItems } from './news.actions';
import { NewsService, INewsFeedItem, INewsFeedSourceName } from '../../pages/home/news/news.service';
import { forkJoin } from 'rxjs';

@Injectable()
export class NewsEffects {
  fetchFeedList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchFeedList),
    switchMap(() => this.newsService.feeds().pipe(
        map((feeds: { [key: string]: INewsFeedSourceName }) => UpdateFeedList({ feeds }))
      ))
  ));

  fetchItems$ = createEffect(() => this.actions$.pipe(
    ofType(FetchItems),
    switchMap((activeFeeds: { feedIds: string[] }) => {
      const feedMap = {};
      for (const feedId of activeFeeds.feedIds) {
        feedMap[feedId] = this.newsService.items(feedId);
      }

      return forkJoin(feedMap).pipe(map((feedItemsMap: { [key: string]: INewsFeedItem[] }) => AddItems({ items: feedItemsMap })));
    })
  ));

  constructor(private actions$: Actions, private newsService: NewsService) {}
}
