import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ICanteen, CanteenService, IDishDetails } from './canteen.service';
import { AppState } from '../../../store';
import { switchMap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { CanteensPage } from './canteens/canteens.page';
import { OverlayEventDetail } from '@ionic/core';

import * as fromSettings from '../../../ngrx/settings/settings.reducer';

@Injectable({
  providedIn: 'root'
})
export class DefaultCanteenGuard  {
  private canteen: Observable<{ remember: boolean; remembered: ICanteen }>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private canteenService: CanteenService,
    private modalController: ModalController
  ) {
    this.canteen = this.store.pipe(select(fromSettings.getCanteen));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.canteen.pipe(
      switchMap((canteen: { remember: boolean; remembered: ICanteen }) => {
        if (canteen.remember && canteen.remembered) {
          return of(this.router.createUrlTree(['/home/canteen', canteen.remembered.id], { queryParams: canteen.remembered.name }));
        } else {
          return from(
            this.modalController.create({
              component: CanteensPage
            })
          ).pipe(
            switchMap((modal: HTMLIonModalElement) => {
              modal.present();
              return from(modal.onDidDismiss()).pipe(
                switchMap((selectedData: OverlayEventDetail<ICanteen>) => {
                  const selectedCanteen = selectedData.data;
                  return of(this.router.createUrlTree(['/home/canteen', selectedCanteen.id], { queryParams: selectedCanteen.name }));
                })
              );
            })
          );
        }
      })
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IDishDetails[]> {
    const canteenId = route.paramMap.get('canteenId');
    if (canteenId) {
      const date = new Date();
      if (date.getDay() === 0) {
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      }
      if (date.getDay() === 6) {
        date.setTime(date.getTime() + 2 * 24 * 60 * 60 * 1000);
      }
      return this.canteenService.dishesByDate(canteenId, date);
    }
  }
}
