import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AlertController,
  Gesture,
  GestureController,
  GestureDetail,
  IonTabs,
  ModalController,
  Platform
} from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { App, AppLaunchUrl } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, Subscription, switchMap, timer } from 'rxjs';
import { ChangelogPage, VERSIONS } from './pages/changelog/changelog.page';
import { AppState, getHydrate } from './store';
import { select, Store } from '@ngrx/store';
import {
  getInvertTabSwipe,
  getLanguage,
  getPush,
  getPushChannel,
  getPushEnabled,
  IPushChannel,
  getWeatherEnabled,
  PushState
} from './ngrx/settings/settings.reducer';
import { PushService, PushChannel } from './services/push.service';
import { filter, first, take, withLatestFrom } from 'rxjs/operators';
import { hasOwnProperty, parseQueryParams } from './helper-functions';
import { AddPushNotification } from './ngrx/push-notification/push-notification.actions';
import { WizardPage } from './pages/wizard/wizard.page';
import { Router, NavigationEnd, Event } from '@angular/router';
import { FetchFeedList, FetchItems } from './ngrx/news/news.actions';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { register as registerSwiper } from 'swiper/element/bundle';
import { selectActiveFeedSubscriptions } from './ngrx/news/news.reducer';
import { FetchWeatherData } from './ngrx/weather/weather.actions';
import { PwaPushNotificationPlugin } from './pwaPushNotification';
import { PWAService } from './services/pwa.service';

// Register swiper custom element
registerSwiper();

const DEFAULT_PUSH_NOTIFICATION_CHANNEL = [PushChannel.LIBRARY, PushChannel.CAMPUSID, PushChannel.TICKET, PushChannel.TIMETABLE];

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(IonTabs, { read: ElementRef }) private tabsElement: ElementRef;
  @ViewChild(IonTabs) private ionTabs: IonTabs;

  private pushState$: Observable<PushState>;
  private pushEnabled$: Observable<boolean>;
  private pushChannel$: Observable<{ [K in IPushChannel]?: boolean }>;
  private pushChannel: { [K in IPushChannel]?: boolean } = null;

  private hydrated$: Observable<boolean>;

  private weatherEnabled$: Observable<boolean>;

  private language$: Observable<string>;
  private backButtonListener: PluginListenerHandle | null = null;
  private appUrlOpenListener: PluginListenerHandle | null = null;
  private pushNotificationRegistrationListener: PluginListenerHandle | null = null;
  private pushNotificationReceivedListener: PluginListenerHandle | null = null;
  private inactivePushNotificationReceivedListener: PluginListenerHandle | null = null;
  private pushNotificationActionPerformedListener: PluginListenerHandle | null = null;
  private subscriptions = new Subscription();

  private tabSwipeGesture: Gesture;
  private invertTabSwipe = false;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private modalController: ModalController,
    private store: Store<AppState>,
    private pushService: PushService,
    private alertController: AlertController,
    private router: Router,
    private gestureController: GestureController,
    public elementRef: ElementRef
  ) {
    this.pushEnabled$ = this.store.pipe(select(getPushEnabled));
    this.pushChannel$ = this.store.pipe(select(getPushChannel));
    this.pushState$ = this.store.pipe(select(getPush));
    this.hydrated$ = this.store.pipe(select(getHydrate)).pipe(first((hydrated: boolean) => hydrated));
    this.language$ = this.store.pipe(select(getLanguage));
    this.weatherEnabled$ = this.store.pipe(select(getWeatherEnabled));

    this.subscriptions.add(
      this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe(this.swipeGestureHandler)
    );
  }

  public updateLanguage(languageCode: string): void {
    const lang = document.createAttribute('lang');
    lang.value = languageCode;
    this.elementRef.nativeElement.parentElement.attributes.setNamedItem(lang);
  }

  ngOnInit() {
    this.translate.addLangs(['de-DE', 'en-US']);
    this.translate.setDefaultLang('de-DE');

    this.subscriptions.add(
      this.language$.subscribe((language: string) => {
        this.translate.use(language);
        this.updateLanguage(language);

        // Update the Language of the PWA PushNotifications and the underlying Service Worker
        if (!Capacitor.isNativePlatform()) {
          PwaPushNotificationPlugin.getInstance().updateLanguage(language);
        }
      })
    );

    this.initializeApp();

    this.subscriptions.add(
      this.hydrated$.subscribe({
        complete: () => {
          this.showWizard();
          this.showChangelog();
          this.setupPushNotification();
          // Keep news feed list up to date
          this.store.dispatch(FetchFeedList());
          this.setupWeatherPolling();
        },
      })
    );

    this.subscriptions.add(
      this.store.select(getInvertTabSwipe).subscribe((invertTabSwipe: boolean) => {
        this.invertTabSwipe = invertTabSwipe;
      })
    );
  }

  ngOnDestroy() {
    this.backButtonListener.remove();
    this.appUrlOpenListener.remove();
    this.pushNotificationRegistrationListener.remove();
    this.pushNotificationReceivedListener.remove();
    this.pushNotificationActionPerformedListener.remove();
    this.inactivePushNotificationReceivedListener.remove();
    this.subscriptions.unsubscribe();
  }

  private initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();

      // Allows to exit the app with the hardware backbutton on android
      this.backButtonListener = App.addListener('backButton', () => {
        if (this.router.url === '/home' || this.router.url === '/personal') {
          App.exitApp();
        }
      });

      /**
       * On iOS you can test universal links on the simulator as follows.
       *
       * Run this command in the terminal:
       * xcrun simctl openurl booted https://tu-dortmund.de/app/campuslink/954661//
       */
      this.appUrlOpenListener = App.addListener('appUrlOpen', (data: AppLaunchUrl) => {
        const url = new URL(data.url);
        // extract the path after '/app'
        const relativeAppPath = url.pathname.substring(5);
        const pathComponents = relativeAppPath.split('/').filter((x) => x);
        const queryParams = parseQueryParams(url.search);

        if (pathComponents.length < 1) {
          return;
        }

        switch (pathComponents[0].toLowerCase()) {
          case 'home':
            this.router.navigateByUrl('/home');
            break;
          case 'personal':
            this.router.navigateByUrl('/personal');
            break;
          case 'news':
            this.router.navigateByUrl('/home/news', { replaceUrl: true });
            break;
          case 'canteen':
            this.router.navigateByUrl('/home/canteen', { replaceUrl: true });
            break;
          case 'events':
            this.router.navigateByUrl('/home/events', { replaceUrl: true });
            break;
          case 'lsf':
            this.router.navigateByUrl('/home/lsf', { replaceUrl: true });
            break;
          case 'employee-search':
            this.router.navigateByUrl('/home/employee-search', { replaceUrl: true });
            break;
          case 'campus-navi':
            this.router.navigateByUrl('/home/campus-navi', { replaceUrl: true });
            break;
          case 'departure-monitor':
            this.router.navigateByUrl('/home/departure-monitor', { replaceUrl: true });
            break;
          case 'feedback':
            this.router.navigateByUrl('/home/feedback', { replaceUrl: true });
            break;
          case 'timetable':
            this.router.navigateByUrl('/personal/timetable', { replaceUrl: true });
            break;
          case 'ticket':
            this.router.navigateByUrl('/personal/ticket', { replaceUrl: true });
            break;
          case 'exams':
            this.router.navigateByUrl('/personal/exams', { replaceUrl: true });
            break;
          case 'ub':
            this.router.navigateByUrl('/personal/ub', { replaceUrl: true });
            break;
          case 'campuslink':
            Browser.open({ url: 'https://service.tu-dortmund.de/rueckverfolgbarkeit' });
            break;
          case 'campusid':
            Browser.open({ url: data.url });
            break;
        }
      });
    });
  }

  private async setupPushNotification() {
    // Change the Push Service Implementation for Native (IOS, Android) or Web (PWA)
    const pushImplementation = Capacitor.isNativePlatform() ? PushNotifications : PwaPushNotificationPlugin.getInstance();

    this.pushChannel$.pipe(take(1)).subscribe((channel: { [K in IPushChannel]?: boolean }) => {
      this.pushChannel = channel;
    });

    // Register device push token to our backend service
    this.pushNotificationRegistrationListener = pushImplementation.addListener('registration', (token: Token) => {
      if(token.value === null) {
        console.error('[PushNotification] registration canceled. Push token value is null');
      }
      const channel = [...DEFAULT_PUSH_NOTIFICATION_CHANNEL];
      if (this.pushChannel != null && this.pushChannel.general) {
        channel.push(PushChannel.GENERAL);
      }
      if (this.pushChannel != null && this.pushChannel.sports) {
        channel.push(PushChannel.SPORTS);
      }
      if(this.pushChannel != null && this.pushChannel.library) {
        channel.push(PushChannel.LIBRARY);
      }

      this.pushService.register(token.value, channel).subscribe({
        next: () => {
          console.info(`[PushNotification] registered user at the backend`);
        },
        error: (error) => {
          console.error(`[PushNotification] failed to register user at the backend. ${error}`);
        }
      });
    });

    // eslint-disable-next-line max-len
    this.pushNotificationReceivedListener = pushImplementation.addListener('pushNotificationReceived', (pushNotification: PushNotificationSchema) => {
      this.store.dispatch(AddPushNotification({ pushNotification }));

      const title = hasOwnProperty(pushNotification, 'title') ? pushNotification.title : pushNotification.data.title;
      const body = hasOwnProperty(pushNotification, 'body') ? pushNotification.body : pushNotification.data.body;

      this.pushService.updateStatistics(pushNotification);

      this.alertController
        .create({
          header: title,
          message: body,
          buttons: ['Ok'],
        })
        .then((alert: HTMLIonAlertElement) => {
          alert.present();
        });
        pushImplementation.removeAllDeliveredNotifications();
    });

    // A Special event to get all the messages that are send while the pwa is inactive
    if(pushImplementation instanceof PwaPushNotificationPlugin) {
      this.inactivePushNotificationReceivedListener = pushImplementation.addListener("inactivePushNotificationReceived", (notifications: PushNotificationSchema[]) => {
        notifications.forEach(notification => {
          this.store.dispatch(AddPushNotification({ pushNotification: notification }))
        });
      });
    }

    /**
     * Event listener for "on notification tap"
     */
    // eslint-disable-next-line max-len
    this.pushNotificationActionPerformedListener = pushImplementation.addListener('pushNotificationActionPerformed', (notificationAction: ActionPerformed) => {
      this.store.dispatch(AddPushNotification({ pushNotification: notificationAction.notification }));

      const title = hasOwnProperty(notificationAction.notification, 'title')
        ? notificationAction.notification.title
        : notificationAction.notification.data.title;
      const body = hasOwnProperty(notificationAction.notification, 'body')
        ? notificationAction.notification.body
        : notificationAction.notification.data.body;

      this.pushService.updateStatistics(notificationAction.notification);

      this.alertController
        .create({
          header: title,
          message: body,
          buttons: ['Ok'],
        })
        .then((alert: HTMLIonAlertElement) => {
          alert.present();
        });

        pushImplementation.removeAllDeliveredNotifications();
    });

    // This is event is triggered if push notifications gets switched on in the settings
    this.subscriptions.add(
      this.pushEnabled$.subscribe(async (enabled: boolean) => {
        if(enabled) {
          const permissionStatus = await pushImplementation.requestPermissions();
          console.info(`[PushNotification] permission was ${permissionStatus.receive}`);
          if (permissionStatus.receive === 'granted') {
            console.info(`[PushNotification] registering...`);
            await pushImplementation.register();
          }
         } else {
        }
      })
    );

    // Update channel if user switched a specific channel on or off in the settings
    this.subscriptions.add(
      this.pushState$.subscribe((pushState: PushState) => {
        if(pushState.enabled) {
          const channel = [...DEFAULT_PUSH_NOTIFICATION_CHANNEL];
          if (pushState.channel != null && pushState.channel.general) {
            channel.push(PushChannel.GENERAL);
          }
          if (pushState.channel != null && pushState.channel.sports) {
            channel.push(PushChannel.SPORTS);
          }
          if (pushState.channel != null && pushState.channel.library) {
            channel.push(PushChannel.LIBRARY);
          }

          // using `null` as the value for `pushId` will not delete the id in the backend database. This has to be done this way
          // since we don't know the `pushId` at this stage.
          this.pushService.register(null, channel).subscribe({
            next: () => {
              console.info(`[PushNotification] update user selected channels`);
            },
            error: (error) => {
              console.error(`[PushNotification] failed to update user selected channels. ${error}`);
            }
          });
        }
      })
    )
  }

  private setupWeatherPolling(): void {
    this.subscriptions.add(
      timer(0, 60000)
        .pipe(
          switchMap((): Observable<boolean> => this.weatherEnabled$.pipe(take(1))),
          filter((weatherEnabled: boolean): boolean => weatherEnabled)
        )
        .subscribe((): void => {
          this.store.dispatch(FetchWeatherData());
        })
    );
  }

  private swipeGestureHandler = (navigationEndEvent: NavigationEnd) => {
    if (this.tabSwipeGesture === null || this.tabSwipeGesture === undefined) {
      // Swipe Tabs Gesture
      this.tabSwipeGesture = this.gestureController.create(
        {
          gestureName: 'swipeTabsGesture',
          el: this.tabsElement.nativeElement,
          threshold: 50,
          maxAngle: 30,
          onEnd: (detail: GestureDetail) => {
            this.tabSwiped(detail);
          },
        },
        true
      );
    }

    if (navigationEndEvent.url === '/home' || navigationEndEvent.url === '/personal' || navigationEndEvent.url === '/') {
      this.tabSwipeGesture.enable();
    } else {
      this.tabSwipeGesture.destroy();
      this.tabSwipeGesture = null;
    }
  };

  private tabSwiped(gestureDetail: GestureDetail) {
    const velocityThreshold = 0.1;
    if (gestureDetail.deltaX > 0 && gestureDetail.velocityX > velocityThreshold) {
      // Swipe to the right
      if (this.invertTabSwipe) {
        this.ionTabs.select('personal');
      } else {
        this.ionTabs.select('home');
      }
    } else if (gestureDetail.deltaX < 0 && gestureDetail.velocityX < velocityThreshold * -1) {
      // Swipe to the left
      if (this.invertTabSwipe) {
        this.ionTabs.select('home');
      } else {
        this.ionTabs.select('personal');
      }
    }
  }

  /**
   * Shows the changelog page if the app starts the first time on a specific version
   */
  private showChangelog() {
    this.newVersion((newVersion: boolean, currentVersion: string) => {
      // eslint-disable-next-line max-len
      const hasChangelog = VERSIONS.filter((version: { version: string; date: string; changes: string[] }) => version.version === currentVersion).length > 0;
      if (newVersion && hasChangelog) {
        from(this.modalController.create({ component: ChangelogPage })).subscribe((modal: HTMLIonModalElement) => {
          modal.onDidDismiss().then(() => {
            // Do version related things that has to be executed after the "Changelog"-View here
          });
          modal.present();
        });
      }
    });
  }

  private showWizard() {
    this.wizardCompleted((isWizardCompleted: boolean) => {
      if (!isWizardCompleted) {
        const wizardModal = this.modalController.create({
          component: WizardPage,
        });

        wizardModal.then((modal: HTMLIonModalElement) => {
          modal.present();
        });
      }
    });
  }

  /**
   * Checks if the app was updated and launched the first time. This is useful to present e.g. changelogs to the user on app startup.
   */
  private async newVersion(callback: (isNewVersion: boolean, currentVersion: string) => any) {
    const version = await Preferences.get({ key: 'lastVersion' });
    const build = await Preferences.get({ key: 'latestBuild' });

    const deviceInfo = Capacitor.isNativePlatform() ? await App.getInfo() : await PWAService.getInfo();
    const currentVersion = deviceInfo.version;
    const currentBuild = deviceInfo.build;

    const isNewVersion =
      (version == null && build == null) || version.value !== currentVersion || build.value !== currentBuild ? true : false;
    callback(isNewVersion, currentVersion);
    Preferences.set({ key: 'lastVersion', value: currentVersion });
    Preferences.set({ key: 'latestBuild', value: currentBuild });
  }

  /**
   * Checks if the app was started before. This is useful to present some introduction screen to the user.
   */
  private firstLaunch(callback: (isFirstLaunch: boolean) => any): void {
    from(Preferences.get({ key: 'firstLaunch' })).subscribe((firstLaunch: { value: string }) => {
      if (firstLaunch.value !== null) {
        const isFirstLaunch = JSON.parse(firstLaunch.value);
        Preferences.set({ key: 'firstLaunch', value: 'false' });
        callback(isFirstLaunch);
      } else {
        Preferences.set({ key: 'firstLaunch', value: 'false' });
        callback(true);
      }
    });
  }

  /**
   * Checks if the app was started before. This is useful to present some introduction screen to the user.
   */
  private wizardCompleted(callback: (isWizardCompleted: boolean) => any): void {
    from(Preferences.get({ key: 'wizardCompleted' })).subscribe((wizardCompleted: { value: string }) => {
      if (wizardCompleted.value !== null) {
        const isWizardCompleted = JSON.parse(wizardCompleted.value);
        callback(isWizardCompleted);
      } else {
        Preferences.set({ key: 'wizardCompleted', value: 'false' }).then();
        callback(false);
      }
    });
  }
}
