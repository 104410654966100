import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromWeather from './weather.reducer';
import { EffectsModule } from '@ngrx/effects';
import { WeatherEffects } from './weather.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(fromWeather.weatherFeatureKey, fromWeather.reducer), EffectsModule.forFeature([WeatherEffects])]
})
export class WeatherModule {}
