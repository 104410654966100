import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { TileModule } from './ngrx/tile/tile.module';
import { NewsModule } from './ngrx/news/news.module';
import { SettingsModule } from './ngrx/settings/settings.module';
import { PushNotificationModule } from './ngrx/push-notification/push-notification.module';
import { CanteenModule } from './pages/home/canteen/canteen.module';
import { DepartureMonitorPageModule } from './pages/home/departure-monitor/departure-monitor.module';
import { load, metaReducers, storedFeatureKeys } from './store';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);

import './helper-functions';
import { SettingsPage } from './pages/settings/settings.page';
import { ChangelogPage } from './pages/changelog/changelog.page';
import { PushNotificationPage } from './pages/push-notification/push-notification.page';
import { WizardPageModule } from './pages/wizard/wizard.module';
import { CampusIdModule } from './ngrx/campus-id/campus-id.module';
import { AccessibilityDisclaimerPage } from './pages/accessibility-disclaimer/accessibility-disclaimer.page';
import { LicensesComponent } from './pages/licenses/licenses.component';
import { LicenseDetailsComponent } from './pages/licenses/details/license-details.component';
import { CacheInterceptor } from './services/cache/cache.interceptor';

import * as fromSettings from './ngrx/settings/settings.reducer';
import * as fromNews from './ngrx/news/news.reducer';
import * as fromTile from './ngrx/tile/tile.reducer';
import * as fromPushNotification from './ngrx/push-notification/push-notification.reducer';
import * as fromCampusId from './ngrx/campus-id/campus-id.reducer';
import * as fromWeather from './ngrx/weather/weather.reducer';


import { AuthenticationInterceptor } from './services/oauth2/authentication.interceptor';
import { AuthenticationService } from './services/oauth2/authentication.service';
import { Hydrate, HydrateFail } from './store.actions';
import { StatisticsService } from './services/statistics.service';
import { WeatherModule } from './ngrx/weather/weather.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';
import { PWAService } from './services/pwa.service';

export const createTranslateLoader = (httpClient: HttpClient) => new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');

export const noopReducer = (state: boolean) => state;

const hydrateStore = (store: Store) => (() => {
  console.info('[ngrx] Load state from storage');
  load(storedFeatureKeys).subscribe({
    next: (data: { [key: string]: string }) => {
      console.info('[ngrx] Hydrating state with value from storage');
      store.dispatch(Hydrate({ state: data }));
    },
    error: () => {
      console.error('[ngrx] Failed to load state from storage');
      store.dispatch(HydrateFail());
    }
  });
});

@NgModule({
    declarations: [
        AppComponent,
        SettingsPage,
        ChangelogPage,
        PushNotificationPage,
        LicensesComponent,
        LicenseDetailsComponent,
        AccessibilityDisclaimerPage,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ animated: true, innerHTMLTemplatesEnabled: true }),
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        HttpClientModule,
        StoreModule.forRoot({
            [fromSettings.settingsFeatureKey]: fromSettings.reducer,
            [fromNews.newsFeatureKey]: fromNews.reducer,
            [fromTile.tileFeatureKey]: fromTile.reducer,
            [fromPushNotification.pushNotificationFeatureKey]: fromPushNotification.reducer,
            [fromCampusId.campusIdFeatureKey]: fromCampusId.reducer,
            [fromWeather.weatherFeatureKey]: fromWeather.reducer,
            hydrate: noopReducer
        }, {
            initialState: {
                [fromSettings.settingsFeatureKey]: fromSettings.initialState,
                [fromNews.newsFeatureKey]: fromNews.initialState,
                [fromTile.tileFeatureKey]: fromTile.initialState,
                [fromPushNotification.pushNotificationFeatureKey]: fromPushNotification.initialState,
                [fromCampusId.campusIdFeatureKey]: fromCampusId.initialState,
                [fromWeather.weatherFeatureKey]: fromWeather.initialState,
                hydrate: false,
            },
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: true,
            },
        }),
        TileModule,
        NewsModule,
        SettingsModule,
        WeatherModule,
        PushNotificationModule,
        CampusIdModule,
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production, // Restrict extension to log-only mode,
            connectInZone: true
        }),
        CanteenModule,
        DepartureMonitorPageModule,
        WizardPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode() && !Capacitor.isNativePlatform(),
          registrationStrategy: 'registerImmediately'
        })
    ],
    providers: [
        AuthenticationService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: hydrateStore, multi: true, deps: [Store] },
        StatisticsService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(private pwaService: PWAService){}
}
