<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/home" [text]="'core.backButton' | translate"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ canteenService.getLocalizedString(canteen.name) }}</ion-title>
    <ion-buttons slot="end">
      <ion-button icon-only (click)="selectCanteen()" [attr.aria-label]="'canteen.select' | translate">
        <ion-icon name="list"></ion-icon>
      </ion-button>
      <ion-button icon-only (click)="openDishInformation()" [attr.aria-label]="'canteen.additives' | translate">
        <ion-icon name="information-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar color="primary">
    <div class="quiver-left">
      <ion-icon class="arrow" [class.arrow-invisible]="isIOS || !showLeftArrow" name="arrow-back"></ion-icon>
    </div>

    <ion-segment
      #weekdaySegment
      (scroll)="scrollHandler($event)"
      scrollable="true"
      [(ngModel)]="selectedWeekday"
      (ionChange)="weekdaySelected($event)"
    >
      <ion-segment-button *ngFor="let weekday of weekdays; let i = index" [value]="weekday" layout="icon-start">
        <ion-icon *ngIf="todayIndex === i" name="calendar"></ion-icon>
        <ion-label>
          {{ canteenService.getLocalizedString(weekday) }}
        </ion-label>
      </ion-segment-button>
    </ion-segment>

    <div class="quiver-right">
      <ion-icon class="arrow" [class.arrow-invisible]="isIOS || !showRightArrow" name="arrow-forward"></ion-icon>
    </div>
  </ion-toolbar>
  <ion-toolbar color="primary" *ngIf="(isOpen$ | async) && weekdays.indexOf(selectedWeekday) === todayIndex" class="openings ion-text-wrap">
    <ion-label>
      {{ 'canteen.open' | translate }}<span *ngIf="closesDate">. {{ 'canteen.closesIn' | translate }} {{ closesDate | openings }}</span>
    </ion-label>
  </ion-toolbar>
  <ion-toolbar
    color="danger"
    *ngIf="(isOpen$ | async) === false && opensDate && weekdays.indexOf(selectedWeekday) === todayIndex"
    class="openings ion-text-wrap"
  >
    <ion-label>
      {{ 'canteen.closed' | translate }}. {{ 'canteen.opens' | translate }} {{ opensDate | openings }}
    </ion-label>
  </ion-toolbar>
  <ion-toolbar color="danger" *ngIf="(isOpen$ | async) === false && !opensDate && weekdays.indexOf(selectedWeekday) === todayIndex" class="openings">
    <ion-label>
      {{ 'canteen.closed' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content>
  <swiper-container #swiper (slidechangetransitionend)="slideChanged()" [initialSlide]="initialSlide">
    <swiper-slide *ngFor="let weekday of weekdays">
      <ion-list>
        <ng-template ngFor let-dish [ngForOf]="weekday.dishes">
          <ion-item *ngIf="dish.displayCounter && dish.counter" class="countername" lines="none">
            <ion-thumbnail class="category-icon" slot="start">
              <img src="assets/canteen/categories/{{ dish.category }}.svg" (error)="hideImage($event)" />
            </ion-thumbnail>
            <ion-label class="ion-text-wrap counter">
              {{ dish.counter }}
            </ion-label>
          </ion-item>
          <ion-item *ngIf="dish.displayCounter && !dish.counter" class="countername">
            <ion-label class="ion-text-wrap counter">
              {{ 'canteen.otherCounter' | translate }}
            </ion-label>
          </ion-item>
          <ion-item class="dishDetails" [attr.data-hasadditives]="dish.additives?.length > 0" (click)="openDishDetails(dish)" [detail]="dish.additives && dish.additives.length">
            <ion-label class="ion-text-wrap">
              <h2>{{ filterAdditives(canteenService.getLocalizedString(dish.title)) }}</h2>
              <p>
                <span *ngFor="let type of dish.type">
                  <img
                    *ngIf="canteenService.getPictogram(type)"
                    class="type"
                    height="20px"
                    role="img"
                    [alt]="type"
                    [src]="canteenService.getPictogram(type)"
                  />
                </span>
              </p>
              <p>
                <span class="price" *ngIf="dish.price.student !== '0,00 €'">S: {{ dish.price.student }}</span>
                <span class="price" *ngIf="dish.price.staff !== '0,00 €'">B: {{ dish.price.staff }}</span>
                <span class="price" *ngIf="dish.price.guest !== '0,00 €'">G: {{ dish.price.guest }}</span>
              </p>
            </ion-label>
          </ion-item>
        </ng-template>
      </ion-list>

      <div class="ion-text-center ion-padding-top">
        <div *ngIf="!weekday.dishes || !weekday.dishes.length">
          {{ 'canteen.menuNotAvailable' | translate }}
        </div>
        <div *ngIf="weekday.dishes && weekday.dishes.length">
          <div>
            {{ 'canteen.poweredBy' | translate }}
          </div>
          <img (click)="openStwdoUrl()" class="logo ion-padding-top" src="assets/canteen/logos/stwdo.svg" />
          <div class="ion-padding-top ion-padding-bottom">
            {{ 'canteen.disclaimer' | translate }}
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</ion-content>
