<ion-app>
  <ion-tabs>
    <ion-tab-bar id="tabbar" slot="bottom">
      <ion-tab-button tab="home">
        <ion-icon name="home" aria-labelledby="home-label"></ion-icon>
        <span id="home-label" style="display:none;">{{ 'core.home' | translate }}</span>
      </ion-tab-button>
      <ion-tab-button tab="personal">
        <ion-icon name="person" aria-labelledby="personal-label"></ion-icon>
        <span id="personal-label" style="display:none;">{{ 'core.personal' | translate }}</span>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-app>
