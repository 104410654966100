import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { App, AppInfo } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';

import { environment } from '../../environments/environment';
import { AuthenticationService } from './oauth2/authentication.service';
import { PWAService } from './pwa.service';

export enum Action {
  tileTapped = 'TILE_TAPPED'
}

export interface StatisticEntry {
  timestamp: Date;
  source: string;
  action: Action;
  details: string;
  roles: string[];
  deviceModel: string;
  deviceManufacturer: string;
  devicePlatform: string;
  deviceOs: string;
  deviceOsVersion: string;
  appVersion: string;
  buildNumber: string;
  userAgent: string;
}

const API_URL: string = environment.apiUrl;
const API_STATISTICS: string = `${API_URL}/app-statistics/v1`;
const API_INPUTS: string = `${API_STATISTICS}/inputs`;

@Injectable({
  providedIn: 'root',
})
export class StatisticsService implements OnDestroy{
  private stats: StatisticEntry[] = [];
  private deviceInfo: DeviceInfo = null;
  private appInfo: AppInfo;
  private listener: PluginListenerHandle;


  constructor(
    private auth: AuthenticationService,
    private httpClient: HttpClient
  ) {
    this.listener = App.addListener('appStateChange', (): void => this.send());
    Device.getInfo().then((deviceInfo: DeviceInfo) => this.deviceInfo = deviceInfo);

    const appInfoProvider = Capacitor.isNativePlatform() ? App.getInfo() : PWAService.getInfo();
    appInfoProvider.then((appInfo: AppInfo) => this.appInfo = appInfo);
  }

  ngOnDestroy(): void {
    this.send();
    void this.listener.remove();
  }

  public addStat(action: Action, source: string, details: string = null): void {
    this.stats.push({
      timestamp: new Date(),
      source,
      action,
      details,
      deviceModel: this.deviceInfo.model,
      deviceManufacturer: this.deviceInfo.manufacturer,
      devicePlatform: this.deviceInfo.platform,
      deviceOs: this.deviceInfo.operatingSystem,
      deviceOsVersion: this.deviceInfo.osVersion,
      roles: this.auth.oauth?.token_info?.roles,
      appVersion: this.appInfo?.version,
      buildNumber: this.appInfo?.build,
      userAgent: window.navigator.userAgent,
    });
  }

  public getState(): StatisticEntry[] {
    return this.stats;
  }

  private send(): void {
    if(this.stats.length > 0) {
      this.httpClient.post(API_INPUTS, this.stats).subscribe({
        next: (): StatisticEntry[] => this.stats = [],
        error: (e): void => console.error(e)
      });
    }
  }
}
