import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface CacheItem {
  datetime: Date;
  response: HttpResponse<any>;
}

// Time in seconds for which the cached item is valid
const CACHE_THRESHOLD = 300;

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: { [key: string]: CacheItem } = {};

  constructor() { }

  public get(request: HttpRequest<any>): HttpResponse<any> {
    // check if item exeeded datetime threshold
    const cachedItem = this.cache[this.generateKey(request)];
    return cachedItem ? cachedItem.response : null;
  }

  public set(request: HttpRequest<any>, response: HttpResponse<any>, datetime?: Date): void {
    this.cache[this.generateKey(request)] = { response, datetime: datetime ?? new Date() };
  }

  public invalidate(request?: HttpRequest<any>) {
    if (request) {
      delete this.cache[this.generateKey(request)];
      return;
    }

    this.cache = {};
  }

  private generateKey(request: HttpRequest<any>): string {
    return request.urlWithParams;
  }
}
