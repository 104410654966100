import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { DishInformationPage } from './dish-information/dish-information.page';
import { MenuPage } from './menu/menu.page';
import { DefaultCanteenGuard } from './default-canteen.guard';
import { CanteensPage } from './canteens/canteens.page';
import { DishDetailsPage } from './dish-details/dish-details.page';
import { DishDetailsResolver } from './dish-details.resolver';
import { OpeningsPipe } from './openings.pipe';

const routes: Routes = [
  {
    path: '',
    component: MenuPage,
    canActivate: [DefaultCanteenGuard]
  },
  {
    path: 'canteens',
    component: CanteensPage
  },
  {
    path: ':canteenId',
    component: MenuPage,
    resolve: {
      dishes: DefaultCanteenGuard
    }
  },
  {
    path: ':canteenId/details/:dispoId',
    component: DishDetailsPage,
    resolve: {
      dish: DishDetailsResolver
    }
  }
];

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), TranslateModule],
    exports: [CanteensPage],
    declarations: [DishInformationPage, MenuPage, CanteensPage, DishDetailsPage, OpeningsPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CanteenModule {}
