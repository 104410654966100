import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Weather, WeatherService } from '../../components/weather/weather.service';
import { switchMap } from 'rxjs';
import { FetchWeatherData, UpdateWeatherData } from './weather.actions';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class WeatherEffects {
  fetchWeather = createEffect(
    () => this.actions$.pipe(
      ofType(FetchWeatherData),
      switchMap(() => this.weatherService.load().pipe(
        map((weather: Weather) => UpdateWeatherData({ weather })),
        catchError(async () => UpdateWeatherData({ weather: null })),
      ))
    ));

  constructor(private actions$: Actions, private weatherService: WeatherService) {}
}
