import { PluginListenerHandle } from "@capacitor/core";
import { ActionPerformed, Channel, DeliveredNotifications, ListChannelsResult, PermissionStatus, PushNotificationSchema, PushNotificationsPlugin, RegistrationError, Token } from "@capacitor/push-notifications";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "src/environments/environment";

export class PwaPushNotificationPlugin implements PushNotificationsPlugin {

  private static _instance: PwaPushNotificationPlugin | undefined = undefined;

  private constructor() {}

  public static getInstance(): PwaPushNotificationPlugin {
    if (PwaPushNotificationPlugin._instance === undefined) {
      PwaPushNotificationPlugin._instance = new PwaPushNotificationPlugin();
    }
    return this._instance;
  }

  private listeners = [];
  private readonly app = initializeApp(environment.pwaFirebaseConfig);
  private readonly messaging = getMessaging(this.app);

  private language: string = "de-DE";

  register(): Promise<void> {
    if (Notification.permission === "granted") {
      getToken(this.messaging, { vapidKey: environment.pwaFirebaseKey }).then((token) => {
        let tokenObj: Token = { value: token };
        this.listeners.filter(listener => listener.key === "registration").forEach(listener => listener.func(tokenObj));
      });

      onMessage(this.messaging, (message) => {
        const notification = this.buildNotification(message);
        this.listeners.filter(listener => listener.key === "pushNotificationReceived").forEach(listener => listener.func(notification));
      });

      window.addEventListener("focus", () => {
        this.getInactiveMessages().then(notifications => {
          this.listeners.filter(listener => listener.key === "inactivePushNotificationReceived").forEach(listener => listener.func(notifications));
        });
      });

    }
    return Promise.resolve();
  }
  unregister(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  getDeliveredNotifications(): Promise<DeliveredNotifications> {
    throw new Error("Method not implemented.");
  }
  removeDeliveredNotifications(delivered: DeliveredNotifications): Promise<void> {
    throw new Error("Method not implemented.");
  }
  removeAllDeliveredNotifications(): Promise<void> {
    return Promise.resolve();
  }
  createChannel(channel: Channel): Promise<void> {
    throw new Error("Method not implemented.");
  }
  deleteChannel(args: { id: string; }): Promise<void> {
    throw new Error("Method not implemented.");
  }
  listChannels(): Promise<ListChannelsResult> {
    throw new Error("Method not implemented.");
  }
  async checkPermissions(): Promise<PermissionStatus> {
    const permission = Notification.permission;
    let permissionState: PermissionState;

    switch (permission) {
      case 'default':
        permissionState = 'prompt';
        break;
      case 'granted':
        permissionState = 'granted';
        break;
      case 'denied':
        permissionState = 'denied';
        break;
      default:
        permissionState = 'prompt';
    }
    return Promise.resolve({ receive: permissionState });
  }
  async requestPermissions(): Promise<PermissionStatus> {
    const permission = await Notification.requestPermission();
    let permissionState: PermissionState;

    switch (permission) {
      case 'default':
        permissionState = 'prompt';
        break;
      case 'granted':
        permissionState = 'granted';
        break;
      case 'denied':
        permissionState = 'denied';
        break;
      default:
        permissionState = 'prompt';
    }
    return Promise.resolve({ receive: permissionState });
  }


  addListener(eventName: "registration", listenerFunc: (token: Token) => void): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(eventName: "registrationError", listenerFunc: (error: RegistrationError) => void): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(eventName: "pushNotificationReceived", listenerFunc: (notification: PushNotificationSchema) => void): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(eventName: "pushNotificationActionPerformed", listenerFunc: (notification: ActionPerformed) => void): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(eventName: "inactivePushNotificationReceived", listenerFunc: (notifications: PushNotificationSchema[]) => void): Promise<PluginListenerHandle> & PluginListenerHandle;
  addListener(eventName: unknown, listenerFunc: unknown): Promise<import("@capacitor/core").PluginListenerHandle> & import("@capacitor/core").PluginListenerHandle {
    this.listeners.push({ key: eventName, func: listenerFunc });

    const removeHandle: PluginListenerHandle = {
      remove: () => {
        this.listeners = this.listeners.filter(listener => listener.key !== eventName);
        return Promise.resolve();
      }
    }
    return Promise.resolve(removeHandle) as Promise<PluginListenerHandle> & PluginListenerHandle;
  }
  removeAllListeners(): Promise<void> {
    this.listeners = [];
    return Promise.resolve();
  }

  public updateLanguage(language: string) {
    this.language = language;
    this.updateServiceWorkerLanguage(language);
  }

  private buildNotification(notification): PushNotificationSchema {
    let title = notification.data.titleDE;
    let body = notification.data.bodyDE;

    switch(this.language) {
      case "en-US":
        title = notification.data.titleEN;
        body = notification.data.bodyEN;
      break;
    }

    let obj: PushNotificationSchema = {
      id: notification.data.messageId,
      data: notification.data,
      title: title,
      body: body
    }
    return obj;
  }

  // This Function requests the messages that got send while the pwa was inactive
  private async getInactiveMessages(): Promise<PushNotificationSchema[]> {
    let notifications: PushNotificationSchema[] = [];
    const messageList = await this.sendMessageToServiceWorker("request-push-notifications")

    messageList.data.forEach(message => {
      notifications.push(this.buildNotification(message));
    });
    
    return notifications;
  }

  private async updateServiceWorkerLanguage(language: string): Promise<void> {
    await this.sendMessageToServiceWorker("update-language/"+language)
    return Promise.resolve();
  }

  // This Functions is for the Connection between the Service Worker and the main Programm
  private async sendMessageToServiceWorker(tag: string): Promise<MessageEvent> {
    const channel = new MessageChannel();

    navigator.serviceWorker.getRegistrations().then((serviceWorkerList) => {
      serviceWorkerList.forEach(serviceWorker => {
        if (serviceWorker.scope === location.origin + "/firebase-cloud-messaging-push-scope") {
          serviceWorker.active?.postMessage(tag, [channel.port2]);
        }
      });
    });

    return new Promise<MessageEvent>((resolve, reject) => {
      try {
        channel.port1.onmessage = (messageList) => {
          resolve(messageList);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}

