import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IDishDetails, CanteenService } from './canteen.service';
import { map, first, mergeAll } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DishDetailsResolver  {
  constructor(private router: Router, private canteenService: CanteenService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IDishDetails> {
    if (this.router.getCurrentNavigation().extras.state) {
      return of(this.router.getCurrentNavigation().extras.state.dish);
    }
    const dispoId = route.paramMap.get('dispoId');
    const canteenId = route.paramMap.get('canteenId');
    const dishes = this.canteenService.dishes(canteenId);
    // const desiredDish = dishes
    //   .pipe(map(weekdayDishes => from(weekdayDishes)))
    //   .pipe(exhaust())
    //   .pipe(map(weekdayDishes => weekdayDishes.dishes))
    //   .pipe(map(dishesAtDay => from(dishesAtDay)))
    //   .pipe(exhaust())
    //   .pipe(first(dish => dish.dispoId === dispoId));
    const desiredDish = dishes
      .pipe(mergeAll())
      .pipe(map(weekdayDishes => weekdayDishes.dishes))
      .pipe(mergeAll())
      .pipe(first(dish => dish.dispoId === dispoId));
    return desiredDish;
  }
}
