import { Weather } from '../../components/weather/weather.service';
import { Action, on, createReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import { UpdateWeatherData } from './weather.actions';

export const weatherFeatureKey = 'weather';

export interface WeatherState {
  weather: Weather
}

export const initialState: WeatherState = { weather: null }

const weatherReducer = createReducer<WeatherState, Action>(
  initialState,
  on(UpdateWeatherData, (_: WeatherState, payload: { weather: Weather }): WeatherState => ({ weather: payload.weather })),
);
export const reducer = (state: WeatherState | undefined, action: Action) => weatherReducer(state, action);


export const selectWeather = createFeatureSelector<WeatherState>(weatherFeatureKey);
export const getWeather = createSelector(selectWeather, (state: WeatherState) => state.weather);
