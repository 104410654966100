import { Component, OnInit, ViewChild, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { ModalController, Platform, IonRouterOutlet } from '@ionic/angular';
import { DishInformationPage } from '../dish-information/dish-information.page';
import { ICanteen, CanteenService, IDishDetails, IOpening } from '../canteen.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CanteensPage } from '../canteens/canteens.page';
import { OverlayEventDetail } from '@ionic/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Browser } from '@capacitor/browser';
import { SwiperContainer } from 'swiper/element';


interface IWeekday {
  id: number;
  de: string;
  en: string;
  dishes: IDishDetails[];
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  @ViewChildren('weekdaySegment') weekdaySegment: QueryList<any>;
  @ViewChild('swiper') slides: ElementRef<SwiperContainer>;

  public canteen: ICanteen;
  public weekdays: IWeekday[] = [
    { id: 1, de: 'Mo', en: 'Mon', dishes: null },
    { id: 2, de: 'Di', en: 'Tue', dishes: null },
    { id: 3, de: 'Mi', en: 'Wed', dishes: null },
    { id: 4, de: 'Do', en: 'Thu', dishes: null },
    { id: 5, de: 'Fr', en: 'Fri', dishes: null },
  ];

  public opensDate: Date;
  public closesDate: Date;
  public isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isOpen = false;
  public isIOS = false;
  public showLeftArrow = false;
  public showRightArrow = false;
  public todayWeekdayIndex: number;
  public todayIndex: number;
  public initialSlide: number = this.weekdays.findIndex((weekday: IWeekday) => weekday.id === MenuPage.today().getDay());
  public selectedWeekday: IWeekday = this.weekdays[this.initialSlide];
  

  private nameSubscription: Subscription;

  constructor(
    public canteenService: CanteenService,
    private modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    private routerOutlet: IonRouterOutlet
  ) {
    this.route.data.subscribe((dishes: { dishes: IDishDetails[] }) => {
      this.weekdays
        .filter((weekday: IWeekday) => MenuPage.today().getDay() === weekday.id)
        .forEach((weekday: IWeekday) => {
          weekday.dishes = dishes.dishes;
        });
    });
    this.isIOS = this.platform.is('ios');

    this.selectedWeekday = this.weekdays[this.initialSlide];
  }

  ionViewDidEnter() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const canteenId = params.get('canteenId');
      this.canteenService.openings(canteenId).subscribe((openings) => {
        this.setOpenings(openings);
      });
    });

    this.showRightArrow = this.weekdaySegment.first.el.scrollWidth > this.weekdaySegment.first.el.clientWidth;
    this.weekdaySegment.first.el.childNodes.forEach((element: HTMLIonSegmentButtonElement) => {
      if (!element.innerText || !this.selectedWeekday) {
        return;
      }
      if (
        element.innerText.trim() === this.selectedWeekday.de.toUpperCase().trim() ||
        element.innerText.trim() === this.selectedWeekday.en.toUpperCase().trim()
      ) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    });
  }

  ngOnInit() {
    this.isOpen$.subscribe((isOpen: boolean) => {
      this.isOpen = isOpen;
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      const canteenId = params.get('canteenId');
      if (!canteenId) {
        this.openCanteenSelection((selectedCateen: OverlayEventDetail<ICanteen>) => {
          if (selectedCateen.data !== undefined) {
            this.navigationByCanteen(selectedCateen.data);
          }
        });
      } else {
        if (!this.canteen) {
          this.nameSubscription = this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
            this.canteen = {
              id: canteenId,
              name: {
                de: paramMap.get('de'),
                en: paramMap.get('en'),
              },
            };
          });
        }
      }
    });

    // Set the initial selection for ion-segment
    this.todayWeekdayIndex = this.weekdays.findIndex((weekday: IWeekday) => weekday.id === MenuPage.today().getDay());
    this.todayIndex = this.weekdays.findIndex((weekday: IWeekday) => weekday.id === new Date().getDay());
    this.selectedWeekday = this.weekdays[this.todayWeekdayIndex];
  }

  scrollHandler(event: any) {
    this.showLeftArrow = event.srcElement.scrollLeft > 0;
    const scrollLeftMax = event.srcElement.scrollWidth - event.srcElement.clientWidth - 1;
    this.showRightArrow = event.srcElement.scrollLeft < scrollLeftMax;
  }

  ionViewWillLeave() {
    this.nameSubscription.unsubscribe();
  }

  public hideImage(event: any): void {
    event.srcElement.parentElement.hidden = true;
  }

  public filterAdditives(input: string): string {
    const filteredAdditives = input.replace(/\s?\(((([0-9]+[a-z]?)+)[,]?)+\)/gi, '');
    return filteredAdditives.replace(/\s?,\s?/gi, ', ');
  }

  public openCanteenSelection(onDidDismissHandler: (canteen: OverlayEventDetail<ICanteen>) => void): void {
    this.modalController
      .create({
        component: CanteensPage,
        componentProps: {
          isModal: true,
        },
        canDismiss: true,
        presentingElement: this.routerOutlet.nativeEl
      })
      .then((modal: HTMLIonModalElement) => {
        modal.onDidDismiss().then(onDidDismissHandler);
        modal.present();
      });
  }

  public selectCanteen(): void {
    this.openCanteenSelection((selectedCateen: OverlayEventDetail<ICanteen>) => {
      if (selectedCateen.data !== undefined) {
        this.navigationByCanteen(selectedCateen.data);
      }
    });
  }

  public openDishInformation(): void {
    this.modalController
      .create({
        component: DishInformationPage,
        canDismiss: true,
        presentingElement: this.routerOutlet.nativeEl
      })
      .then((modal: HTMLIonModalElement) => {
        modal.present();
      });
  }

  public openDishDetails(dish: IDishDetails): void {
    if (dish && dish.dispoId && dish.additives && dish.additives.length) {
      this.router.navigate(['details', dish.dispoId], { relativeTo: this.route, state: { dish } });
    }
  }

  public weekdaySelected(selectedWeekday: any): void {
    const eventTarget: any = selectedWeekday.target;
    const weekdayObject = selectedWeekday.detail.value;

    if(eventTarget) {
      eventTarget.childNodes.forEach((element: HTMLIonSegmentButtonElement, index: number) => {
        if (element.textContent.includes(weekdayObject.de) || element.textContent.includes(weekdayObject.en)) {
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      });
    }
    const weekdayId = selectedWeekday.detail.value.id;
    const selectedWeekdayIndex = this.weekdays.findIndex((weekday: IWeekday) => weekday.id === weekdayId);

    if (!this.weekdays[selectedWeekdayIndex].dishes) {
      this.canteenService
        .dishesByDate(this.canteen.id, this.dateFromWeekdayOfCurrentWeek(weekdayId))
        .subscribe((dishes: IDishDetails[]) => {
          this.weekdays[selectedWeekdayIndex].dishes = dishes;
        });
    }
    this.slides.nativeElement.swiper.slideTo(selectedWeekdayIndex);
  }

  public slideChanged(): void {
    const slideTargetIndex = this.slides.nativeElement.swiper.activeIndex;
    if(slideTargetIndex !== undefined) {
      this.selectedWeekday = this.weekdays[slideTargetIndex];

      const weekdayId = this.selectedWeekday.id;
      const selectedWeekdayIndex = this.weekdays.findIndex((weekday: IWeekday) => weekday.id === weekdayId);
  
      if (!this.weekdays[selectedWeekdayIndex].dishes) {
        this.canteenService
          .dishesByDate(this.canteen.id, this.dateFromWeekdayOfCurrentWeek(weekdayId))
          .subscribe((dishes: IDishDetails[]) => {
            this.weekdays[selectedWeekdayIndex].dishes = dishes;
          });
      }
    }
  }

  public openStwdoUrl() {
    const url = 'https://www.stwdo.de';
    Browser.open({ url });
  }

  private getDateFromTime(time: string): Date {
    if (!time) {
      return null;
    }
    const date = new Date();
    const [hours, minutes, seconds] = time.split(':');
    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setSeconds(+seconds);
    return date;
  }

  private setOpenings(openings: IOpening): void {
    if (openings) {
      const morningStartDate = this.getDateFromTime(openings.morningStartTime);
      const morningEndDate = this.getDateFromTime(openings.morningEndTime);
      const now = new Date();
      let isOpen = false;
      if (morningStartDate == null) {
        return;
      }
      if (now.getTime() < morningEndDate.getTime()) {
        isOpen = now.getTime() > morningStartDate.getTime();
        this.isOpen$.next(now.getTime() > morningStartDate.getTime());
        this.opensDate = morningStartDate;
        this.closesDate = morningEndDate;
      } else {
        const afternoonStartDate = this.getDateFromTime(openings.afternoonStartTime);
        if (afternoonStartDate == null) {
          this.opensDate = null;
          this.closesDate = null;
          this.isOpen$.next(false);
          return;
        }
        const afternoonEndDate = this.getDateFromTime(openings.afternoonEndTime);
        isOpen = now.getTime() > afternoonStartDate.getTime();
        this.isOpen$.next(now.getTime() > afternoonStartDate.getTime());
        this.opensDate = afternoonStartDate;
        this.closesDate = afternoonEndDate;
      }
      if (!isOpen) {
        setTimeout(() => {
          this.setOpenings(openings);
        }, this.opensDate.getTime() - now.getTime());
      } else {
        setTimeout(() => {
          this.setOpenings(openings);
        }, this.closesDate.getTime() - now.getTime());
      }
    }
  }

  private static today(): Date {
    const today = new Date();
    if (today.getDay() === 6) {
      today.setDate(today.getDate() + 2);
    } else if (today.getDay() === 0) {
      today.setDate(today.getDate() + 1);
    }
    return today;
  }

  private navigationByCanteen(canteen: ICanteen): void {
    if (canteen) {
      this.router.navigate(['../', canteen.id], {
        relativeTo: this.route,
        replaceUrl: true,
        state: canteen,
        queryParams: { de: canteen.name.de, en: canteen.name.en },
      });
    }
  }

  private dateFromWeekdayOfCurrentWeek(weekday: number): Date {
    if ([0, 1, 2, 3, 4, 5, 6].indexOf(weekday) === -1) {
      return null;
    }
    const today = MenuPage.today();
    // Adjust when day is 0 (sunday)
    const diff = weekday === 0 ? today.getDate() + (7 - today.getDay()) : today.getDate() + (weekday - today.getDay());
    return new Date(today.setDate(diff));
  }
}
