import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromNews from './news.reducer';
import { EffectsModule } from '@ngrx/effects';
import { NewsEffects } from './news.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(fromNews.newsFeatureKey, fromNews.reducer), EffectsModule.forFeature([NewsEffects])]
})
export class NewsModule {}
