<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/home" [text]="'core.backButton' | translate"></ion-back-button>
    </ion-buttons>
    <ion-title>Details</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item lines="full" class="ion-text-center">
    <ion-label *ngIf="dish.counter">
      <img src="assets/canteen/categories/{{ dish.category }}.svg" (error)="hideImage($event)" class="title-image" />
      <span class="title">{{ dish.counter }}</span>
    </ion-label>
    <ion-label *ngIf="!dish.counter" class="title">
      {{ "canteen.counterNotAvailable" | translate }}
    </ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-label class="ion-text-wrap">
      {{ canteenService.getLocalizedString(dish.title) }}
      <p class="pictograms">
        <span *ngFor="let type of dish.type">
          <img *ngIf="canteenService.getPictogram(type)" class="type" height="20px" role="img" [alt]="type" [src]="canteenService.getPictogram(type)" />
        </span>
      </p>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="additives">
    <ion-label>
      <b>{{ 'canteen.information.additives' | translate }}</b>
    </ion-label>
  </ion-item>
  <ion-grid *ngIf="additives">
    <ion-row *ngFor="let additive of getNamedAdditives()">
      <ion-col size="2">
        <b>{{ additive.id }}</b>
      </ion-col>
      <ion-col>
        {{ additive.name }}
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-item *ngIf="dish.price.student !== '0,00 €' && dish.price.staff !== '0,00 €' && dish.price.guest !== '0,00 €'">
    <ion-label>
      <b>{{ 'canteen.prices' | translate }}</b>
    </ion-label>
  </ion-item>
  <ion-grid class="ion-text-center">
    <ion-row class="ion-text-center">
      <ion-col *ngIf="dish.price.student !== '0,00 €'">
        <b>{{ 'canteen.price.students' | translate }}</b>
      </ion-col>
      <ion-col *ngIf="dish.price.staff !== '0,00 €'">
        <b>{{ 'canteen.price.employees' | translate }}</b>
      </ion-col>
      <ion-col *ngIf="dish.price.guest !== '0,00 €'">
        <b>{{ 'canteen.price.guests' | translate }}</b>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col *ngIf="dish.price.student !== '0,00 €'">
        {{ dish.price.student }}
      </ion-col>
      <ion-col *ngIf="dish.price.staff !== '0,00 €'">
        {{ dish.price.staff }}
      </ion-col>
      <ion-col *ngIf="dish.price.guest !== '0,00 €'">
        {{ dish.price.guest }}
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
