import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { filter, map, tap } from 'rxjs/operators';
import { ChangeLanguage, ToggleWeather } from './settings.actions';
import { TranslateService } from '@ngx-translate/core';
import { ILanguage } from './settings.reducer';
import { FetchWeatherData } from '../weather/weather.actions';

@Injectable()
export class SettingsEffects {
  changeLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(ChangeLanguage),
    tap((data: { language: ILanguage }) => {
      this.translate.use(data.language);
    })
  ), { dispatch: false });

  reloadWeather$ = createEffect(() => this.actions$.pipe(
    ofType(ToggleWeather),
    filter(data => data.enabled),
    map(() => FetchWeatherData())
  ));

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
  ) {}
}
