import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface IconTranslationKeys { [no: number]: string}

export const iconTranslationKeys: IconTranslationKeys = {
  0: 'unknown',
  1: 'clearSky',
  2: 'lightClouds',
  3: 'partlyCloudy',
  4: 'cloudy',
  5: 'rain',
  6: 'sleet',
  7: 'snow',
  8: 'rainShower',
  9: 'snowShower',
  10: 'sleetShower',
  11: 'lightFog',
  12: 'denseFog',
  13: 'freezingRain',
  14: 'thunderstorms',
  15: 'drizzle',
  16: 'sandstorm',
  101: 'clearSky',
  102: 'lightClouds',
  103: 'partlyCloudy',
  104: 'cloudy',
  105: 'rain',
  106: 'sleet',
  107: 'snow',
  108: 'rainShower',
  109: 'snowShower',
  110: 'sleetShower',
  111: 'lightFog',
  112: 'denseFog',
  113: 'freezingRain',
  114: 'thunderstorms',
  115: 'drizzle',
  116: 'sandstorm',
}
export interface CurrentWeather {
  dateTime: number,       // Timestamp of the update from api
  windSpeed: number,        // windspeed in m/s
  windDirection: number,    // winddirection
  windGust1: number,        // windgusts next hour in m/s
  windGust24: number,       // windgusts today in m/s
  temperature: number,      // current temperature
  temperatureMin: number,   // min temperature today
  temperatureMax: number,   // max temperature today
  pressure: number,         // current msl pressure
  precipitation1: number,   // precipitation next hour
  precipitation24: number,  // precipitation today
  symbolIdx1: number,       // symbol index next hour
  symbolIdx24: number,      // symbol index today
  uv: number,               // current uv index
  sunrise: Date,            // todays time of sunrise
  sunset: Date              // todays time of sunset
}

export interface Weather {
  currentWeather: CurrentWeather
}

const API_URL: string = environment.apiUrl;
const API_SERVICE: string = `${API_URL}/weather/v1`;
// const API_SERVICE: string = `http://localhost:8090/v1`;
const API_CURRENT: string = `${API_SERVICE}/current`;

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(
    private http: HttpClient
  ) { }

  load(): Observable<Weather> {
    return this.http.get<Weather>(API_CURRENT);
  }
}
