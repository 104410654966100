<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="primary">
      <ion-button *ngIf="(isEnd | async) === false" fill="clear" (click)="next()">
        {{'wizard.next' | translate}}
      </ion-button>
      <ion-button *ngIf="isEnd | async" fill="clear" (click)="done()">
        {{'core.done' | translate}}
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'wizard.title' | translate }}</ion-title>
    <ion-buttons slot="secondary">
      <ion-button fill="clear" (click)="done()">
        {{'wizard.skip' | translate}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="ion-padding">
  <swiper-container #swiper (slidechangetransitionstart)="slideChanged()">
    <swiper-slide class="slide">
      <ion-icon name="globe" class="ion-align-self-center"></ion-icon>
      <h2 class="ion-align-self-center">{{ 'settings.lang.title' | translate }}</h2>
      <p class="ion-align-self-center">
        {{'wizard.language.message' | translate}}
      </p>

      <ion-radio-group [value]="translate.currentLang" (ionChange)="selectLanguage($event)">
        <ion-item *ngFor="let lang of languages">
          <ion-label>{{ lang.translationKey | translate }}</ion-label>
          <ion-radio slot="start" [value]="lang.lang" slot="end"></ion-radio>
        </ion-item>
      </ion-radio-group>

      <p class="ion-align-self-center">
        <ion-button fill="clear" (click)="next()">
          {{ 'wizard.next' | translate }}
        </ion-button>
      </p>
    </swiper-slide>

    <swiper-slide class="slide">
      <img class="ion-align-self-center" src="assets/wizard/icon-badge.svg" />
      <h2 class="ion-align-self-center">{{ 'wizard.push-notification.header' | translate }}</h2>
      <p class="ion-align-self-center">
        {{ 'wizard.push-notification.message' | translate }}
      </p>

      <ion-item>
        <ion-label class="ion-text-wrap">
          <h2>{{ 'wizard.push-notification.activate' | translate }}</h2>
        </ion-label>

        <ion-toggle #push name="push" [checked]="push$ | async" (ionChange)="togglePush(push)"></ion-toggle>
      </ion-item>

      <ion-item>
        <ion-label class="ion-text-wrap">
          <h2>{{ 'settings.push.channel.header' | translate }}</h2>
          <p>{{ 'settings.push.channel.subheader' | translate }}</p>
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <h2>{{ 'settings.push.channel.general' | translate }}</h2>
        </ion-label>

        <ion-toggle
          #general
          name="general"
          [disabled]="!push.checked"
          [checked]="pushChannelGeneral$ | async"
          (ionChange)="togglePushChannel(general)"
        ></ion-toggle>
      </ion-item>

      <ion-item>
        <ion-label>
          <h2>{{ 'settings.push.channel.sports' | translate }}</h2>
        </ion-label>

        <ion-toggle
          #sports
          name="sports"
          [disabled]="!push.checked"
          [checked]="pushChannelSports$ | async"
          (ionChange)="togglePushChannel(sports)"
        ></ion-toggle>
      </ion-item>

      <ion-item>
        <ion-label>
          <h2>{{ 'settings.push.channel.library' | translate }}</h2>
        </ion-label>

        <ion-toggle
          #library
          name="library"
          [disabled]="!push.checked"
          [checked]="pushChannelLibrary$ | async"
          (ionChange)="togglePushChannel(library)"
        ></ion-toggle>
      </ion-item>

      <p class="ion-align-self-center">
        <ion-button fill="clear" (click)="done()">
          {{'core.done' | translate}}
        </ion-button>
      </p>
    </swiper-slide>
  </swiper-container>
</ion-content>
