import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FetchTiles, AddTiles, FetchTilesFail } from './tile.actions';
import { TileService, DynamicTile } from './tile.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of, Observable, forkJoin } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { isString } from '../../helper-functions';

@Injectable()
export class TileEffects {
  fetchTiles$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTiles),
    switchMap(() => this.tileService.getTiles().pipe(
        switchMap((tiles: DynamicTile[]) => {
          const tileIds = tiles.map((tile: DynamicTile) => tile.id);

          // Create map of tileId => Observable<Blob>
          const icons$: { [key: number]: Observable<Blob> } = {};
          tileIds.forEach((tileId: number) => (icons$[tileId] = this.tileService.getIcon(tileId)));

          // Resolve that map and map it to tileId => DataURI
          return forkJoin(icons$).pipe(
            switchMap((icons: { [key: number]: Blob }) => {
              const icons2$: { [key: number]: Observable<string> } = {};
              for (const [iconId, iconBlob] of Object.entries(icons)) {
                icons2$[iconId] = this.readFile(iconBlob);
              }
              return forkJoin(icons2$);
            }),
            // Add each DataURI to the corresponding tileId
            map((icons2: { [key: number]: SafeUrl }) =>
            tiles.map((tile: DynamicTile) => {
                tile.icon = icons2[tile.id];
                return tile;
              }))
          );
        }),
        map((tiles: DynamicTile[]) => AddTiles({ tiles })),
        catchError(() => of(FetchTilesFail()))
      ))
  ));

  constructor(
    private actions$: Actions,
    private tileService: TileService,
    private domSanitizer: DomSanitizer
  ) {}

  private readFile(blob: Blob): Observable<SafeUrl> {
    return new Observable<SafeUrl>(obs => {
      if (!(blob instanceof Blob)) {
        obs.error(new Error('`blob` must be an instance of File or Blob.'));
        return;
      }

      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => {
        if (isString(reader.result)) {
          obs.next(this.domSanitizer.bypassSecurityTrustUrl((reader.result as string)));
        }
      };
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }
}
