import { Component, OnInit } from '@angular/core';
import { IStationDeparture, IStation } from './departure-monitor.service';
import { OverlayEventDetail } from '@ionic/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { StationsPage } from './stations/stations.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-departure-monitor',
  templateUrl: './departure-monitor.page.html',
  styleUrls: ['./departure-monitor.page.scss'],
})
export class DepartureMonitorPage implements OnInit {
  public departures: IStationDeparture[];
  public stationName: string;
  private nameSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private routerOutlet: IonRouterOutlet
  ) {
    this.route.data.subscribe((departures: { departures: IStationDeparture[] }) => {
      this.departures = departures.departures;
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const stationId = params.get('stationId');
      if (stationId) {
        if (!this.stationName) {
          this.nameSubscription = this.route.queryParamMap.subscribe(paramMap => {
            this.stationName = paramMap.get('name');
          });
        }
      } else {
        this.openStationSelection();
      }
    });
  }

  ionViewWillLeave() {
    this.nameSubscription.unsubscribe();
  }

  openStationSelection(): void {
    this.modalController
      .create({
        component: StationsPage,
        componentProps: {
          isModal: true
        },
        canDismiss: true,
        presentingElement: this.routerOutlet.nativeEl
      })
      .then((modal: HTMLIonModalElement) => {
        modal.onDidDismiss().then(selectedStation => {
          if (selectedStation.data !== undefined) {
            this.router.navigate(['../', selectedStation.data.id], {
              relativeTo: this.route,
              replaceUrl: true,
              queryParams: { name: selectedStation.data.name }
            });
          }
        });
        modal.present();
      });
  }

}
