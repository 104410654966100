import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContext, HttpContextToken, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheService } from './cache.service';
import { startWith, tap } from 'rxjs/operators';

export const CACHE = new HttpContextToken<boolean>(() => false);
export const CACHE_ENABLED = new HttpContext().set(CACHE, true);

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cacheService: CacheService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(CACHE)) {
      const cachedResponse = this.cacheService.get(request);
      if (cachedResponse) {
        return this.cacheRequest(request, next).pipe(startWith(cachedResponse));
      }
      return this.cacheRequest(request, next);
    }

    return next.handle(request);
  }

  private cacheRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.cacheService.set(request, event);
        }
      })
    );
  }
}
