import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromTile from './tile.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TileEffects } from './tile.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTile.tileFeatureKey, fromTile.reducer),
    EffectsModule.forFeature([TileEffects])
  ]
})
export class TileModule { }
