import { Component, OnInit } from '@angular/core';
import { CanteenService, ICanteen } from '../canteen.service';
import { ModalController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { ChangeRememberedCanteen } from 'src/app/ngrx/settings/settings.actions';
import { Observable } from 'rxjs';

import * as fromSettings from 'src/app/ngrx/settings/settings.reducer';

@Component({
  selector: 'app-canteens',
  templateUrl: './canteens.page.html',
  styleUrls: ['./canteens.page.scss']
})
export class CanteensPage implements OnInit {
  public canteens: ICanteen[];
  private canteen: Observable<{ remember: boolean; remembered: ICanteen }>;
  private rememberCanteen: boolean;

  constructor(public canteenService: CanteenService, private modalController: ModalController, private store: Store<AppState>) {
    this.canteen = this.store.pipe(select(fromSettings.getCanteen));
  }

  ngOnInit() {
    this.canteenService.canteens().subscribe((canteens: ICanteen[]) => {
      this.canteens = canteens;
    });
    this.canteen.subscribe((storeCanteen: { remember: boolean; remembered: ICanteen }) => {
      this.rememberCanteen = storeCanteen.remember;
    });
  }

  public dismiss(canteen?: ICanteen) {
    if (canteen && this.rememberCanteen) {
      this.store.dispatch(ChangeRememberedCanteen({ canteen }));
    }
    this.modalController.dismiss(canteen);
  }
}
