import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

export const AUTHENTICATE = new HttpContextToken<boolean>(() => false);
export const AUTHENTICATED = new HttpContext().set(AUTHENTICATE, true);

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(AUTHENTICATE)) {
      return this.authenticationService.handleAuthenticatedRequest(request, next);
    }

    // Handle request that doesn't require authentication
    return next.handle(request);
  }
}
