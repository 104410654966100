import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IDishDetails, CanteenService, IAdditive } from '../canteen.service';

@Component({
  selector: 'app-dish-details',
  templateUrl: './dish-details.page.html',
  styleUrls: ['./dish-details.page.scss'],
})
export class DishDetailsPage implements OnInit {
  dish: IDishDetails;
  additives: IAdditive[];

  constructor(private router: Router, public canteenService: CanteenService, private route: ActivatedRoute) {
    this.route.data.subscribe(dishData => this.dish = dishData.dish);
  }

  ngOnInit() {
    this.canteenService.additives().subscribe(additiveList => {
      this.additives = additiveList;
    });
  }

  getNamedAdditives() {
    const additiveList = [];
    this.dish.additives.forEach(additive => {
      const additiveName = this.additives.find(additiveObject => additiveObject.id === additive.trim());
      if (additiveName) {
        additiveList.push({
          id: additive.trim(),
          name: this.canteenService.getLocalizedString(additiveName.name)
        });
      } else {
        additiveList.push({
          id: additive.trim(),
          name: ''
        });
      }
    });
    this.dish.additives.sort((item1, item2) => {
      const number1 = +item1.match(/[0-9]+/gi)[0];
      const number2 = +item2.match(/[0-9]+/gi)[0];
      if (number1 < number2) {
        return -1;
      } else if (number2 < number1) {
        return 1;
      } else {
        const letter1 = item1.match(/[a-z]/gi);
        const letter2 = item2.match(/[a-z]/gi);
        if (letter1.length === 0 || letter2.length === 0) {
          return 0;
        } else {
          const ascii1 = letter1[0].charCodeAt(0);
          const ascii2 = letter2[0].charCodeAt(0);
          if (ascii1 < ascii2) {
            return -1;
          } else if (ascii2 < ascii1) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    });
    return additiveList;
  }

  getAdditives() {
    const addtiveList = [];
    this.dish.additives.forEach(additive => {
      const additiveName = this.additives.find(additiveObject => additiveObject.id === additive.trim());
      if (additiveName) {
        addtiveList.push(this.canteenService.getLocalizedString(additiveName.name));
      } else {
        addtiveList.push(additive);
      }
    });
    return addtiveList;
  }

  public hideImage(event: any): void {
    event.srcElement.parentElement.hidden = true;
  }
}
