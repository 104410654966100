import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export interface Provider {
    id: string;
    description: {
        de: string;
        en: string;
    };
    callbackUrls: CallbackUrl[];
}

export interface CallbackUrl {
    id: string;
    url: string;
}

export const CampusIdInit = createAction('[Campus Id] Initialize Campus Id');
export const CampusIdStore = createAction('[Campus Id] Store secret key', props<{ secret: string; uuid: string }>());
export const CampusIdFailed = createAction('[Campus Id] Initialization failed', props<{ error: HttpErrorResponse }>());

export const CampusIdFetchProvider = createAction('[Campus Id] Fetch provider list');
export const CampusIdUpdateProvider = createAction('[Campus Id] Update provider list', props<{ provider: Provider[] }>());

export const CampusIdValidate = createAction('[Campus Id] Validate TOTP', props<{ uuid: string; totp: string }>());

export const CampusIdReset = createAction('[Campus Id] Reset Campus Id');
export const CampusIdNoop = createAction('[Campus Id] Noop');

