import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';

export interface ICanteen {
  id: string;
  name: {
    de: string;
    en: string;
  };
}

export interface IDish {
  date: string;
  dishes: Array<IDishDetails>;
}

export interface IDishDetails {
  title: {
    de: string;
    en: string;
  };
  type: Array<string>;
  category: string;
  additives: Array<string>;
  price?: {
    student: string;
    staff: string;
    guest: string;
  };
  counter?: string;
  displayCounter: boolean;
  dispoId: string;
  position: number;
}

export interface IAdditive {
  id: string;
  name: {
    de: string;
    en: string;
  };
}

export interface ICategory {
  id: string;
  name: {
    de: string;
    en: string;
  };
}

export interface IType {
  id: string;
  name: {
    de: string;
    en: string;
  };
}

export interface IOpening {
  morningStartTime: string;
  morningEndTime: string;
  afternoonStartTime: string;
  afternoonEndTime: string;
}

const API_URL = environment.apiUrl;
const API_SERVICE_URL = `${API_URL}/canteen-menu/v3`;
const API_CANTEENS = `${API_SERVICE_URL}/canteens`;
const API_CANTEEN = `${API_SERVICE_URL}/canteens/{0}?expand=true`;
const API_DISH = `${API_SERVICE_URL}/canteens/{0}/{1}?expand=true`;
const API_CATEGORIES = `${API_SERVICE_URL}/categories`;
const API_CATEGORY = `${API_SERVICE_URL}/categories/{0}`;
const API_ADDITIVES = `${API_SERVICE_URL}/additives`;
const API_TYPES = `${API_SERVICE_URL}/types`;
const API_TYPE = `${API_SERVICE_URL}/types/{0}`;
const API_OPENINGS = `${API_SERVICE_URL}/canteens/{0}/openings`;

@Injectable({
  providedIn: 'root'
})
export class CanteenService {
  constructor(private httpClient: HttpClient, private translate: TranslateService) {}

  public canteens(): Observable<ICanteen[]> {
    return this.httpClient.get<ICanteen[]>(API_CANTEENS);
  }

  public dishes(canteenId: string): Observable<IDish[]> {
    return this.httpClient.get<any>(API_CANTEEN.format(canteenId)).pipe(
      map(result => {
        const dishes: IDish[] = new Array<IDish>();
        for (const date in result) {
          if (date !== null) {
            dishes.push({
              date,
              dishes: result[date]
            });
          }
        }
        return dishes;
      })
    );
  }

  public dishesByDate(canteenId: string, date: Date): Observable<IDishDetails[]> {
    const stringDate = formatDate(date, 'yyyy-MM-dd', 'de');
    return this.httpClient.get<any>(API_DISH.format(canteenId, stringDate)).pipe(
      map((dishes: IDishDetails[]) => {
        if (!dishes) {
          return [];
        }
        dishes = dishes.sort((dish1, dish2) => {
          if (dish1.position < dish2.position) {
            return -1;
          } else if (dish2.position < dish1.position) {
            return 1;
          } else {
            return 0;
          }
        });
        const categories = dishes.filter(dish => dish.counter).map(dish => dish.category);
        categories
          .filter((item, index) => categories.indexOf(item) === index)
          .forEach(category => {
            dishes.find(dish => dish.category === category).displayCounter = true;
          });
        const firstCounterless = dishes.find(dish => !dish.counter);
        if (firstCounterless) {
          firstCounterless.displayCounter = true;
        }
        return dishes;
      })
    );
  }

  public openings(canteenId: string): Observable<IOpening> {
    return this.httpClient.get<IOpening>(API_OPENINGS.format(canteenId));
  }

  public additives(): Observable<IAdditive[]> {
    return this.httpClient.get<IAdditive[]>(API_ADDITIVES);
  }

  public categories(): Observable<ICategory[]> {
    return this.httpClient.get<ICategory[]>(API_CATEGORIES);
  }

  public category(categoryId: string): Observable<ICategory> {
    return this.httpClient.get<ICategory>(API_CATEGORY.format(categoryId));
  }

  public types(): Observable<IType[]> {
    return this.httpClient.get<IType[]>(API_TYPES);
  }

  public type(typeId: string): Observable<IType> {
    return this.httpClient.get<IType>(API_TYPE.format(typeId));
  }

  public getLocalizedString(stringObject: { de: string; en: string }): string {
    return stringObject[this.getLocale()];
  }

  public getLocale(): string {
    switch (this.translate.currentLang) {
      case 'de-DE': {
        return 'de';
      }

      case 'en-US': {
        return 'en';
      }

      default: {
        break;
      }
    }
  }

  public getPictogram(type: string): string | null {
    const pictogramLocation = 'assets/canteen/pictograms';
    switch (type) {
      case 'A':
        return `${pictogramLocation}/icon-artgerecht.svg`;
      case 'B':
        return `${pictogramLocation}/icon-klimateller.svg`;
      case 'F':
        return `${pictogramLocation}/icon-fisch.svg`;
      case 'G':
        return `${pictogramLocation}/icon-gefluegel.svg`;
      case 'L':
        return `${pictogramLocation}/icon-lamm.svg`;
      case 'N':
        return `${pictogramLocation}/icon-vegan.svg`;
      case 'R':
        return `${pictogramLocation}/icon-rind.svg`;
      case 'S':
        return `${pictogramLocation}/icon-schwein.svg`;
      case 'V':
        return `${pictogramLocation}/icon-vegetarisch.svg`;
      case 'W':
        return `${pictogramLocation}/icon-wild.svg`;
      case 'K':
        return `${pictogramLocation}/icon-kinderteller.svg`;
      case 'V oder N':
        return `assets/canteen/types/${type}.svg`;
      default:
        return null;
    }
  }
}
