import { Pipe, PipeTransform, ChangeDetectorRef, NgZone, OnDestroy } from '@angular/core';
import { HumanizeDurationLanguage, HumanizeDuration } from 'humanize-duration-ts';
import { CanteenService } from './canteen.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'openings',
  pure: false,
})
export class OpeningsPipe implements PipeTransform, OnDestroy {
  langService: HumanizeDurationLanguage;
  humanizer: HumanizeDuration;

  currentTime: number;
  currentValue: Date;
  currentText: string;
  currentTimer: number | null;

  constructor(
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private canteenService: CanteenService,
    private translate: TranslateService
  ) {
    this.langService = new HumanizeDurationLanguage();
    this.humanizer = new HumanizeDuration(this.langService);
    this.humanizer.setOptions({
      language: this.canteenService.getLocale(),
      delimiter: ' ',
      units: ['h', 'm'],
      round: true,
    });
  }

  getText(date: Date): string {
    const now = new Date();
    const duration = Math.abs(now.getTime() - date.getTime());
    if (duration >= 60000) {
      return ` in ${this.humanizer.humanize(duration)}`;
    } else {
      return this.translate.instant('canteen.opensAnytime');
    }
  }

  transform(value: Date): string {
    if (value === null || value === undefined) {
      return;
    }

    if (value.getTime() !== this.currentTime) {
      this.currentTime = value.getTime();
      this.currentValue = value;
      this.removeTimer();
      this.createTimer();
      this.currentText = this.getText(value);
    } else {
      this.createTimer();
    }
    return this.currentText;
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private createTimer() {
    if (this.currentTimer) {
      return;
    }

    this.currentTimer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.currentText = this.getText(this.currentValue);
          this.currentTimer = null;
          this.ngZone.run(() => this.cdRef.markForCheck());
        }, 1000);
      } else {
        return null;
      }
    });
  }

  private removeTimer() {
    if (this.currentTimer) {
      window.clearTimeout(this.currentTimer);
      this.currentTimer = null;
    }
  }
}
