import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppInfo } from '@capacitor/app';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PWAService {

  constructor(
    private update: SwUpdate,
    private translate: TranslateService
  ) {
    if (update.isEnabled) {
      this.checkUpdate();
      this.applyUpdate();
    }
  }

  static getInfo(): Promise<AppInfo> {
    return Promise.resolve({ id: '-', name: '-', version: 'PWA', build: 'PWA' });
  }

  // Adds a Subscription to an Interval to check every x Minutes for Updates
  checkUpdate(): void {
    const updateInterval = interval(5*60*1000);

    updateInterval.subscribe(async () => {
      try {
        const updateFound = await this.update.checkForUpdate();
        if (updateFound) {
          console.log("New Update found")
        }
      } catch (err) {
        console.error("App update check error",err);
      }
    });
  }

  // Adds a Subscription to the Version Update to reload the site when the new Version is ready
  applyUpdate(): void {
    this.update.versionUpdates.subscribe(evt => {
      if (evt.type === 'VERSION_READY' && confirm(this.translate.instant("pwa.updatePossible"))) {
        document.location.assign(document.location.origin+document.location.pathname);
      }
    })
  }
}
