import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface INewsFeedSource {
  id: string;
  title: {
    de: string;
    en: string;
  };
}

export interface INewsFeedSourceName {
  name: string;
}

export interface INewsFeedItem {
  title: string;
  description: string;
  date: string;
  link: string;
  images: string[];
  content: string[];
  id: string;
}

export interface INewsItemRead extends INewsFeedItem {
  read: boolean;
  feedId: string;
}

export interface FeedSubscriptions {
  [key: string]: boolean;
}

const API_URL = environment.apiUrl;
const API_SERVICE = `${API_URL}/news/v2`;
// const API_SERVICE = `http://localhost:8092`;
const API_FEEDS = `${API_SERVICE}/feeds`;
const API_ITEMS = `${API_SERVICE}/feeds/{0}`;
const API_ICON = `${API_SERVICE}/feeds/{0}/icon`;
const API_IMAGE = `${API_SERVICE}/feeds/{0}/{1}`;

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(private http: HttpClient) {}

  public feeds(): Observable<{ [key: string]: INewsFeedSourceName }> {
    return this.http.get<{ [key: string]: INewsFeedSourceName }>(API_FEEDS);
  }

  public items(feed: string): Observable<INewsFeedItem[]> {
    return this.http.get<INewsFeedItem[]>(API_ITEMS.format(feed));
  }

  public iconURL(feed: string): string {
    return API_ICON.format(feed);
  }

  public imageURL(feed: string, image: string): string {
    if (image.startsWith('http')) {
      return image;
    }
    return API_IMAGE.format(feed, image);
  }
}
