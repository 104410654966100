<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/" [text]="'core.backButton' | translate"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ stationName }}</ion-title>
    <ion-buttons slot="end">
      <ion-button icon-only (click)="openStationSelection()" [attr.aria-label]="'departure-monitor.stations' | translate">
        <ion-icon name="list"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <table *ngIf="departures">
    <thead>
      <tr>
        <th>{{ 'departure-monitor.time' | translate }}</th>
        <th>{{ 'departure-monitor.line' | translate }}</th>
        <th>{{ 'departure-monitor.direction' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let departure of departures">
        <td class="time">
          {{ departure.date_time | date:'HH:mm' }} <br />
          <div class="delay" *ngIf="departure.delay !== 0 && departure.delay !== null">
            <img src='assets/departure/delay.svg' /> (+{{ departure.delay }})
          </div>
          <div class="delay" *ngIf="departure.delay === null">
            <img src='assets/departure/delay.svg' /> {{ 'departure-monitor.cancelled' | translate }}
          </div>
        </td>
        <td class="line">
          <span class="line"><img class="type" src="assets/departure/{{departure.transport_type}}.svg" /></span>
          <span class="line">{{ departure.line }}</span>
        </td>
        <td>
          <div>{{ departure.direction }}</div>
          <div class="route">{{ departure.line_description }}</div>
        </td>
      </tr>
    </tbody>
  </table>
  <ion-item *ngIf="!departures?.length">
    <ion-label class="ion-text-wrap">
      <p>
        {{ 'departure-monitor.timetableNotAvailable' | translate }}
      </p>
    </ion-label>
  </ion-item>
</ion-content>
