import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { hasOwnProperty, isString } from '../../helper-functions';
import { SafeUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../../services/oauth2/authentication.service';
import { AUTHENTICATED } from '../../services/oauth2/authentication.interceptor';

export enum Locale {
  de_DE,
  en_US,
  de,
  en,
}

export interface LocalizedString {
  id: number;
  locale: Locale | string;
  value: string;
}

export enum DynamicTileState {
  DISABLED,
  PREVIEW,
  ACTIVE,
}

export interface DynamicTile {
  id: number;
  name: LocalizedString[];
  content: LocalizedString[];
  start: Date | string;
  end: Date | string;
  state: DynamicTileState | string;
  icon?: string | SafeUrl;
}

const API_URL = environment.apiUrl;
const API_SERVICE = `${API_URL}/tiles/v2`;
const API_TILES = `${API_SERVICE}`;
const API_TILE = `${API_TILES}/{0}`;
const API_TILE_ICON = `${API_SERVICE}/{0}/icon`;

@Injectable({
  providedIn: 'root',
})
export class TileService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  public getTiles(): Observable<DynamicTile[]> {
    return this.http
      .get<DynamicTile[]>(API_TILES, { context: this.authenticationService.isAuthenticated() ? AUTHENTICATED : null })
      .pipe(
        map((tiles: DynamicTile[]) => {
          const normalizedTiles: DynamicTile[] = [];
          for (const tile of tiles) {
            normalizedTiles.push(this.normalizeTile(tile));
          }
          return normalizedTiles;
        })
      );
  }

  public getTile(id: number): Observable<DynamicTile> {
    return this.http.get<DynamicTile>(API_TILE.format(id)).pipe(
      map((tile: DynamicTile) => this.normalizeTile(tile))
    );
  }

  public getIcon(tileId: number): Observable<Blob> {
    const iconURL = API_TILE_ICON.format(tileId);
    return this.http.get(iconURL, { responseType: 'blob' });
  }

  private normalizeTile(tile: DynamicTile): DynamicTile {
    let normalizedTile: DynamicTile = {
      id: tile.id,
      start: null,
      end: null,
      state: null,
      name: null,
      content: null,
    };

    if (isString(tile.start)) {
      normalizedTile.start = new Date(tile.start);
    }

    if (isString(tile.end)) {
      normalizedTile.end = new Date(tile.end);
    }

    if (hasOwnProperty(tile, 'name')) {
      normalizedTile = { ...normalizedTile, name: [] };
      for (const name of tile.name) {
        const normalizedName = {
          id: name.id,
          value: name.value,
          locale: null,
        };

        if (Locale[name.locale] === Locale.de_DE) {
          normalizedName.locale = Locale.de;
        } else if (Locale[name.locale] === Locale.en_US) {
          normalizedName.locale = Locale.en;
        }
        normalizedTile.name.push(normalizedName);
      }
    }

    if (hasOwnProperty(tile, 'content')) {
      normalizedTile = { ...normalizedTile, content: [] };
      for (const content of tile.content) {
        const normalizedContent = {
          id: content.id,
          value: content.value,
          locale: null,
        };

        if (Locale[content.locale] === Locale.de_DE) {
          normalizedContent.locale = Locale.de;
        } else if (Locale[content.locale] === Locale.en_US) {
          normalizedContent.locale = Locale.en;
        }
        normalizedTile.content.push(normalizedContent);
      }
    }

    if (hasOwnProperty(tile, 'state')) {
      normalizedTile.state = DynamicTileState[tile.state];
    }

    return normalizedTile;
  }
}
